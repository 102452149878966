import axiosInstance from './axios.config.onscore';
const RESTAPI_VERSION = '/v1';
const BASE_ENDPOINT_NAME = 'macro/afip/cuits';

export const afipService = {

    getCuits: function (customerId) {
        return axiosInstance.get(`${RESTAPI_VERSION}/${BASE_ENDPOINT_NAME}`, {
            params: {
              customerId: customerId
            }});
    },

};

export default afipService;