import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import { useTranslation } from 'react-i18next';
import MacroStepPage from './shared/MacroStepPage';
import Loader from '../components/loader/Loader';
import restartImg from '../assets/img-carpeta.svg';
import Toaster from '../components/alerts/Toaster';

export const RestartDocumentsUpload = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
        }
    }, [financialProductRequest]);

    const handleNextPressed = (event) => {
        event.preventDefault();

        nextStep();
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const nextStep = async () => {
        try {
            setLoading(true);
            const request = {
                inputMap: {},
            };
            await task.completeTask(request);
        } catch (error) {
            var msg = t(`WORKFLOW_ERROR.GENERAL.DESCRIPTION`);
            setMessage(msg);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    return (
        <MacroStepPage {...props} noBackground={true}>
            <Loader loading={loading} />
            <Grid container elevation={0} square alignItems="center">
                <Grid item xs={12} container justifyContent="center">
                    <img src={restartImg} className="mt-2" alt="" />
                </Grid>
                <Grid item container className="mt-4 center" xs={12} alignItems="center">
                    <h2 style={{ textAlign: 'center' }}>Hubo un error en la carga de documentos </h2>
                </Grid>
                <Grid item container className="mt-4 center" xs={12}>
                    <h3 style={{ textAlign: 'center' }}>Debés cargarlos nuevamente</h3>
                </Grid>

                <Grid item container className="mt-4 center" xs={12}>
                    <div className="mb-footer startButton center mb-20-web mt-20-web">
                        <Button onClick={handleNextPressed} className="width-100-per width-80-res" variant="contained" color="primary">
                            Continuar
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
        </MacroStepPage>
    );
};
