import React, { useEffect } from 'react';
import minifiedHashService from '../../services/miniHashService.service';
import { useHistory } from 'react-router-dom';
import Loader from '../loader/Loader';
import redirectUtils from '../bpm/useRedirect.jsx'; 
import { financialProductsRequestsService } from '../../services/financialProductsRequestsService';
import { bpmService } from '../../services/bpm.service';

export const RedirectFromMinihash = (props) => {
    const { redirectTo } = props;
    const { minHash, orig } = props.match.params;
    const history = useHistory();
    const redirect = redirectUtils();

    useEffect(() => {
        async function redirectFromMinihash() {
            try {
                localStorage.clear();
                const minifiedRequestUrlResponse = await minifiedHashService.getMinifiedRequestUrlFromMinhash(minHash);
                if(minifiedRequestUrlResponse.data.totalItems < 1) {
                    history.push(`/noRetryable/INVALID_MINIHASH`);
                } else {
                    const result = minifiedRequestUrlResponse.data.result[0];
                
                    localStorage.setItem('access_token', result.authentication.access_token);
                    localStorage.setItem('refresh_token', result.authentication.refresh_token);
                    
                    const financialRequestId = result.referenceId;
                    
                    const financialRequestResponse = await financialProductsRequestsService.getFinancialProductsRequest(financialRequestId);

                    const businessKey = financialRequestResponse.data.id;

                    switch(redirectTo) {
                        case 'contract':
                            
                            const documentResponse = await financialProductsRequestsService.getDocument(businessKey, financialRequestResponse.data.documentData.contract.documentProviderId);
                            const fileResponse = await financialProductsRequestsService.getDocumentFile(
                                businessKey,
                                documentResponse.data.id,
                                documentResponse.data.files[0].id
                            );

                            window.open(window.URL.createObjectURL(fileResponse.data), "_self");
                        
                        break;
                        default: // redirect to pending task
                            
                            const processInstanceId = financialRequestResponse.data.businessProcessInfo.processInstanceId;
                            const pendingTasksResponse = await bpmService.searchWorkflowBusinessInstancePendingTasks(processInstanceId);
                            
                            const currentTask = pendingTasksResponse.data.result[0].taskDefinitionId;
                            if (currentTask) {
                                history.push(`/${currentTask}/${businessKey}${history.location.search}`);
                            } else {
                                redirect.redirectFPRByStatus(businessKey, financialRequestResponse.data.status);
                            }    
                        break;
                    }
                }
            } catch (error) {
                history.push(`/noRetryable/GENERAL`);
            }
        }
        redirectFromMinihash();
    }, [minHash]);

    return (
        <>
            <div>
                <Loader loading="true" />
            </div>
        </>
    );
};
export default RedirectFromMinihash;
