import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap, InfoWindow } from 'react-google-maps';
import { UNDEFINED_VALUE } from '../userInputs/userInputConstants';

const Map = ({ userInput, onChange, markerList, ...props }) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    const [defaultLat, setDefaultLat] = useState(UNDEFINED_VALUE);
    const [defaultLng, setDefaultLng] = useState(UNDEFINED_VALUE);

    useEffect(() => {
        if (markerList.length > 0) {
            setDefaultLat(markerList[0].address.latitude);
            setDefaultLng(markerList[0].address.longitude);
        }
    }, [markerList]);

    useEffect(() => {
        if (selectedMarker && selectedMarker !== UNDEFINED_VALUE) {
            onChange(userInput, selectedMarker);
        } else {
            onChange(userInput, null);
        }
    }, [selectedMarker]);

    console.log(markerList);

    return (
        <GoogleMap defaultZoom={10} center={{ lat: defaultLat ? defaultLat : 0, lng: defaultLng ? defaultLng : 0 }}>
            {markerList.map((marker, i) => {
                return (
                    <Marker
                        key={i}
                        position={{ lat: marker.address.latitude, lng: marker.address.longitude }}
                        text="My Marker"
                        onClick={() => {
                            setSelectedMarker(marker);
                            setSelectedId(i);
                        }}>
                        {selectedMarker && selectedId === i && (
                            <InfoWindow
                                onCloseClick={() => {
                                    setSelectedMarker(null);
                                    setSelectedId(null);
                                }}>
                                <span>{marker.description}</span>
                            </InfoWindow>
                        )}
                    </Marker>
                );
            })}
        </GoogleMap>
    );
};

export default withScriptjs(withGoogleMap(Map));
