import React, { useEffect, useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TermsModal from './TermsModal';
import termService from '../../services/term.service';
import Toaster from './../../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';

const AcceptGeneralTermsCheckbox = (props) => {
    const [accepted, setAccepted] = useState(false);
    const [terms, setTerms] = useState(null);
    const [termsVisible, setTermsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        //getTerms();
        const termsandConditions = {
            id: "BNPL_TYC",
            url: "terms.html"
        };
        setTerms(termsandConditions);
    }, []);

    /*
    const getTerms = async () => {
        try {
            const terms = (await termService.getTerms('macro-bnpl')).data;
            setTerms(terms);
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`) || 'Error al aceptar los terminos y condiciones');
            } else {
                setMessage(error.message);
            }
            setOpen(true);
        }
    };
    */

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleAcceptChange = (event) => {
        setAccepted(event.target.checked);
        props.onChange(event.target.checked, terms.id);
    };

    const handleTermsAccepted = () => {
        setAccepted(true);
        setTermsVisible(false);
        props.onChange(true, terms.id);
    };

    const handleTermsRejected = () => {
        setAccepted(false);
        setTermsVisible(false);
        props.onChange(false, terms.id);
    };

    const handleTermsClick = (event) => {
        event.preventDefault();
        setTermsVisible(true);
    };

    const label = (
        <span className="fs-14">
            Leí y acepto los{' '}
            <a href="#" onClick={handleTermsClick}>
                términos y condiciones
            </a>
        </span>
    );

    if (!terms) {
        return null;
    }
    return (
        <div className="flex center mt-1">
            <FormGroup row>
                <TermsModal staticTerms={true} open={termsVisible} termsUrl={terms.url} onReject={handleTermsRejected} onAccept={handleTermsAccepted} />
                <FormControlLabel
                    control={<Checkbox checked={accepted} onChange={handleAcceptChange} name="accepted" color="secondary" />}
                    label={label}
                />
                <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
            </FormGroup>
        </div>
    );
};

export default AcceptGeneralTermsCheckbox;
