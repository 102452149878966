import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: theme.spacing(4, 0, 2, 0),
    },
}));

export const CheckEmail = (props) => {
    const { onChange, loading } = props;
    const [email, setEmail] = useState(props.mail);
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [isValid, setIsValid] = useState(false);
    const [mailModified, setMailModified] = useState(false);
    const classes = useStyles();

    const emailRegex = /\S+@\S+\.\S+/;

    const userInput = {
        name: 'Email',
        userInputOptions: {
            disabled: false,
            required: true,
            label: 'E-mail',
            hint: 'Ej: ejemplo@gmail.com',
        },
    };

    const isEmail = (email) => {
        return emailRegex.test(email);
    };

    const handleEmailChanged = (event) => {
        const value = event.target.value.trim();
        setMailModified(true);
        setEmail(value);
        setIsValid(isEmail(value));
        onChange(value, isEmail(value));
    };

    return (
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                label={userInput.userInputOptions.label}
                onChange={handleEmailChanged}
                color="secondary"
                error={!isValid && mailModified}
                disabled={loading || userInput.userInputOptions.disabled}
                fullWidth={true}
                required={userInput.userInputOptions.required}
                name={userInput.name}
                value={email}
                helperText={userInput.userInputOptions.hint}
                className={classes.textField}
            />
        </Grid>
    );
};
