import React, { useEffect } from 'react';
import QRCode from 'qrcode';
import { Box } from '@material-ui/core';
import useFinancialProductsRequests from '../../hooks/useFinancialProductsRequest';

export const Qrcode = (props) => {
    const { businessKey } = props;
    const financialProductRequest = useFinancialProductsRequests(businessKey);

    useEffect(() => {
        async function generateQR() {
            try {
                QRCode.toCanvas(
                    document.getElementById('canvas'),
                    financialProductRequest.requestContextData.minifiedRequestUrl + '/orig/qr',
                    {
                        color: {
                            dark: '#000000',
                            light: '#FAFAFA',
                        },
                    },
                    function (error) {
                        if (error) console.error(error);
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
        if (financialProductRequest) {
            generateQR();
        }
    }, [financialProductRequest]);

    return (
        <>
            <div style={{ zIndex: 100 }}>
                <Box style={{ backgroundColor: 'transparent', paddingBottom: 0, zIndex: 101 }}>
                    <canvas id="canvas" style={{ zIndex: 999 }} />
                </Box>
            </div>
        </>
    );
};
export default Qrcode;
