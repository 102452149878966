import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import UserInput from './UserInput';
import '../../styles/components/userInputs.scss';
import Loader from '../loader/Loader';
import useFinancialProductsRequests from '../../hooks/useFinancialProductsRequest';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const CustomerDataUserInputs = (props) => {
    const { hashKey, stepName, sameLineIndexes, form, onComplete, normalizedAddressAlternatives, onSelectAlternative } = props;
    const [inputGroups, setInputGroups] = useState([]);
    const [userInputFilled, setUserInputFilled] = useState({});
    const [contextData, setContextData] = useState({});
    const [conditioningValues, setConditioningValues] = useState({});
    const [loading, setLoading] = useState(false);

    const [parentElementConditionalValue, setParentElementConditionalValue] = useState(null);

    const [elementConditionalId, setElementConditionalId] = useState(null);
    const [elementConditionalValue, setElementConditionalValue] = useState(null);

    const financialProductRequest = useFinancialProductsRequests(hashKey);

    const [normalizedAddressAlternativeIndex, setNormalizedAddressAlternativeIndex] = useState(null);

    const sort = (groups) => {
        let normalizeAddressGroupSet = false;
        groups.forEach((group) => {
            switch (group.name.toString().toLowerCase()) {
                case 'datos personales':
                    group.index = 1;
                    //group.name = '';
                    break;
                case 'datos de contacto':
                    group.index = 2;
                    break;
                case 'domicilio':
                    group.index = 3;
                    break;
                case 'declaración jurada':
                    group.index = 4;
                    break;
                case 'apertura de cuenta':
                    group.index = 5;
                    break;
                default:
                    group.index = 100;
            }
            if (!group.name.toLowerCase().includes('domicilio')) {
                return;
            }
            normalizeAddressGroupSet = true;
            if (!normalizeAddressGroupSet) {
                group.normalizeAddress = true;
            }
            

        });
        groups.sort((g1, g2) => {
            if (g1.index > g2.index) return 1;
            if (g2.index > g1.index) return -1;

            return 0;
        });
        return groups;
    };

    useEffect(() => {
        async function getUserInputFields() {
            setInputGroups(sort(form.groups));
        }

        if (form) {
            getUserInputFields();
        }
    }, [form]);

    useEffect(() => {
        const validarForm = async () => {
            onComplete(userInputFilled, contextData);
        };

        validarForm();
    }, [userInputFilled]);

    useEffect(() => {
        async function getUserInputFields() {
            setInputGroups(sort(form.groups));
        }

        if (form) {
            getUserInputFields();
        }
    }, [normalizedAddressAlternatives]);

    const handleUserInputChange = (userInput, value, description) => {
        setUserInputFilled((previousState) => {
            return { ...previousState, [userInput.name]: value && value !== null ? value.toString() : null };
        });
        setContextData((previousState) => {
            return { ...previousState, [userInput.name]: description && description !== null ? description.toString() : null };
        });

        // Si es la provincia, seteo el valor en el parentElementConditionalId y value
        if (userInput.id === 36) {
            setParentElementConditionalValue(value);
        }
        setElementConditionalId(userInput.id);
        setElementConditionalValue(value);
    };

    const getConditioningValue = (variableName) => {
        const conditioningValue = conditioningValues[variableName];
        if (!conditioningValue) {
            return null;
        }
        return conditioningValue.value;
    };

    const getParentConditioningValue = (variableName) => {
        const conditioningValue = conditioningValues[variableName];
        if (!conditioningValue) {
            return null;
        }
        const parentVariableName = conditioningValue.parentVariableName;
        const parentConditioningValue = conditioningValues[parentVariableName];
        if (!parentConditioningValue) {
            return null;
        }
        return parentConditioningValue.value;
    };

    const handleNormalizedAddressAlternativeChange = (event) => {
        setNormalizedAddressAlternativeIndex(Number(event.target.value));
        
        onSelectAlternative(Number(event.target.value));
    };
    
    return (
        <>
            <Loader loading={loading} />
            {inputGroups.map(
                (group, index) =>
                    normalizedAddressAlternatives &&
                    group.name.toLowerCase().includes('domicilio') && (
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <p className="fw-400 mt-3">¿Cuál es tu domicilio?</p>
                                <p className="fs-14 fw-400 mt-1 mb-2">Encontramos más de una dirección.</p>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={normalizedAddressAlternativeIndex}
                                        onChange={handleNormalizedAddressAlternativeChange}>
                                        {normalizedAddressAlternatives.map((address, index) => (
                                            <FormControlLabel
                                                className={`normalization-modal-form-group${
                                                    normalizedAddressAlternativeIndex === index ? '-selected' : ''
                                                }`}
                                                value={index}
                                                control={<Radio />}
                                                label={`${address.streetName} ${address.streetNumber},
                        ${address.neighborhoodName || ''} CP ${address.zipCodeValue} - ${address.cityName} - ${address.stateName}`}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )
            )}

            {!normalizedAddressAlternatives &&
                inputGroups.map((group, index) => (
                    <Grid container xs={12} className="mb-1">
                        <Box key={index} style={{ width: '100%' }}>
                            <Hidden xsUp={normalizedAddressAlternatives} implementation="css">
                                <Grid container xs={12} direction="row" justify="left" alignItems="left" className="">
                                    <Grid item xs={12} className="">
                                        <h4 className="mt-1">{group.name}</h4>
                                    </Grid>
                                    <Grid container xs={12} className="">
                                        {group.variables.map((variable, index) => (
                                            <Grid item xs={sameLineIndexes && sameLineIndexes.some((v) => v === index) ? 6 : 12} sm={12}>
                                                <Box key={variable.name}>
                                                    <Grid item xs={12} sm={12}>
                                                        <UserInput
                                                            hashKey={hashKey}
                                                            userInput={variable}
                                                            stepName={stepName}
                                                            onChange={handleUserInputChange}
                                                            parentConditioningValue={getParentConditioningValue(variable.name)}
                                                            conditioningValue={getConditioningValue(variable.name)}
                                                            parentElementConditionalValue={parentElementConditionalValue}
                                                            elementConditionalId={elementConditionalId}
                                                            elementConditionalValue={elementConditionalValue}
                                                        />
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Box>
                    </Grid>
                ))}
        </>
    );
};

export default CustomerDataUserInputs;
