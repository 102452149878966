import React, { useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Toaster = (props) => {
    const { toast } = props;
    const [open, setOpen] = useState(false);
    const [currentToast, setCurrentToast] = useState(null);

    useEffect(() => {
        if (toast) {
            setCurrentToast(toast);
            setOpen(true);
        }
    }, [toast]);

    function Alert(props) {
        return <MuiAlert elevation={1} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setCurrentToast(null);
    };

    if (currentToast) {
        return (
            <Snackbar open={open} onClose={handleClose}>
                <Alert onClose={handleClose} severity={currentToast.type || 'error'}>
                    {currentToast.message}
                </Alert>
            </Snackbar>
        );
    }
    return null;
};

export default Toaster;
