import axiosInstance from './axios.config.onscore';
import { CATALOG_COUNTRY_ID, getEnv } from '../env';

const RESTAPI_VERSION = '/v1';
const BASE_ENDPOINT_NAME = 'countries';

export const userInputComboService = {
    getComboList: function (key, parentConditionalComboKey, conditionalComboKey, pageNumber) {
        if (key === 'customerCivilStatus') {
            return axiosInstance.get(`${RESTAPI_VERSION}/catalogs?tableName=maritalStatus&pageNumber=1&pageSize=1000`);
        } else if (key === 'customerAddressProvince' || key === 'customerBankAccountProvince') {
            return axiosInstance.get(
                `${RESTAPI_VERSION}/${BASE_ENDPOINT_NAME}/${getEnv(CATALOG_COUNTRY_ID)}/states?pageNumber=${pageNumber}&pageSize=1000`
            );
        } else if (key === 'customerAddressCity') {
            return axiosInstance.get(
                `${RESTAPI_VERSION}/${BASE_ENDPOINT_NAME}/${getEnv(
                    CATALOG_COUNTRY_ID
                )}/states/${conditionalComboKey}/cities?pageNumber=${pageNumber}&pageSize=1000`
            );
        } else if (key === 'customerAddressCP') {
            return axiosInstance.get(
                `${RESTAPI_VERSION}/${BASE_ENDPOINT_NAME}/${getEnv(
                    CATALOG_COUNTRY_ID
                )}/states/${parentConditionalComboKey}/cities/${conditionalComboKey}/zip-codes?pageNumber=${pageNumber}&pageSize=1000`
            );
        } else if (key === 'customerFinancialOfficeId') {
            return axiosInstance.get(
                `${RESTAPI_VERSION}/financial-offices?countryId=${getEnv(
                    CATALOG_COUNTRY_ID
                )}&fundingEntityId=${parentConditionalComboKey}&stateId=${conditionalComboKey}&pageNumber=${pageNumber}&pageSize=1000`
            );
        } else {
            return [];
        }
    },

    getState: function (country, state) {
        return axiosInstance.get(`${RESTAPI_VERSION}/${BASE_ENDPOINT_NAME}/${country}/states/${state}`);
    },

    getFinancialOfficeBankAccountProvinceUserInputFields: function () {
        const userInputs = {
            groups: [
                {
                    variables: [
                        {
                            formElementType: 'MAP',
                            id: 36,
                            name: 'customerFinancialOfficeId',
                            description: 'Mapa de provincias',
                            order: 40,
                            target: 'DEFAULT',
                            label: 'Seleccione la Sucursal',
                            elementConditionalDefinitions: [],
                            optionItems: [],
                            required: true,
                        },
                    ],
                    name: '',
                },
            ],
        };

        return userInputs;
    },

    getBankAccountProvinceUserInputFields: function () {
        const userInputs = {
            groups: [
                {
                    variables: [
                        {
                            formElementType: 'TYPEAHEAD',
                            id: 36,
                            name: 'customerBankAccountProvince',
                            description: 'Combo de provincias',
                            order: 40,
                            target: 'DEFAULT',
                            label: 'Provincia',
                            elementConditionalDefinitions: [],
                            optionItems: [],
                            required: true,
                            helperText: 'Donde se radicará',
                        },
                    ],
                    name: '',
                },
            ],
        };

        return userInputs;
    },

    getDDJJUserInputFields: function () {
        const userInputs = {
            groups: [
                {
                    variables: [
                        {
                            formElementType: 'CHECK',
                            userInputOptions: {
                                label: 'Alcanzado dentro de la nómina de Personas Expuestas Políticamente',
                                formElementType: 'CHECK',
                                hint: 'Que desempeña o desempeñó funciones públicas destacadas o es familiar de un funcionario.',
                                hintAction: null,
                                mask: null,
                                pattern: null,
                                variableNameForDefaultValue: null,
                                datasourceNameForComboOptions: null,
                                datasourceNameForDefaultValue: null,
                                order: 50,
                                textToShowToSelect: null,
                                disabled: false,
                                defaultValue: null,
                                conditionalUserInputName: null,
                                conditionalUserInputValue: null,
                                comboData: [],
                                inputsThatDependsOnMe: [],
                                verification: 'NONE',
                                validationType: null,
                                facetValidation: null,
                                subDivision: false,
                                needReplication: false,
                                mustHidenIfUniqueResult: false,
                                minDate: null,
                                maxDate: null,
                                required: false,
                            },
                            group: 'Declaración Jurada',
                            name: 'customerPEP',
                            datasourceName: 'userinput',
                            expression: null,
                            stateProcesses: [
                                {
                                    text: 'bnplAffidavitDataInput',
                                },
                            ],
                            id: 308,
                            stateProcessesNames: ['bnplAffidavitDataInput'],
                        },
                        {
                            formElementType: 'CHECK',
                            userInputOptions: {
                                label: 'Sujeto Obligado ante la UIF',
                                formElementType: 'CHECK',
                                hint: 'Debe informar operaciones sospechosas de lavado de activos o financiamiento del terrorismo.',
                                hintAction: null,
                                mask: null,
                                pattern: null,
                                variableNameForDefaultValue: null,
                                datasourceNameForComboOptions: null,
                                datasourceNameForDefaultValue: null,
                                order: 1000,
                                textToShowToSelect: null,
                                disabled: false,
                                defaultValue: null,
                                conditionalUserInputName: null,
                                conditionalUserInputValue: null,
                                comboData: [],
                                inputsThatDependsOnMe: [],
                                verification: 'NONE',
                                validationType: null,
                                facetValidation: null,
                                subDivision: false,
                                needReplication: false,
                                mustHidenIfUniqueResult: false,
                                minDate: null,
                                maxDate: null,
                                required: false,
                            },
                            group: 'Declaración Jurada',
                            name: 'customerSujetoObligadoUIF',
                            datasourceName: 'userinput',
                            expression: null,
                            stateProcesses: [
                                {
                                    text: 'bnplAffidavitDataInput',
                                },
                            ],
                            id: 310,
                            stateProcessesNames: ['bnplAffidavitDataInput'],
                        },
                        {
                            formElementType: 'CHECK',
                            userInputOptions: {
                                label: 'OCDE (CRS)',
                                type: 'CHECK',
                                hint: 'Poseo un país de Residencia fiscal adicional/distinto de Argentina.',
                                hintAction: null,
                                mask: null,
                                pattern: null,
                                variableNameForDefaultValue: null,
                                datasourceNameForComboOptions: null,
                                datasourceNameForDefaultValue: null,
                                order: 1020,
                                textToShowToSelect: null,
                                disabled: false,
                                defaultValue: null,
                                conditionalUserInputName: null,
                                conditionalUserInputValue: null,
                                comboData: [],
                                inputsThatDependsOnMe: [],
                                verification: 'NONE',
                                validationType: null,
                                facetValidation: null,
                                subDivision: false,
                                needReplication: false,
                                mustHidenIfUniqueResult: false,
                                minDate: null,
                                maxDate: null,
                                required: false,
                            },
                            group: 'Declaración Jurada',
                            name: 'customerOCDE',
                            datasourceName: 'userinput',
                            expression: null,
                            stateProcesses: [
                                {
                                    text: 'bnplAffidavitDataInput',
                                },
                            ],
                            id: 311,
                            stateProcessesNames: ['bnplAffidavitDataInput'],
                        },
                        {
                            formElementType: 'CHECK',
                            userInputOptions: {
                                label: 'FATCA - US Person',
                                type: 'CHECK',
                                hint: 'Me encuentro alcanzado por la Ley FATCA, ya que poseo obligaciones tributarias en los EE.UU. o sus territorios.',
                                hintAction: null,
                                mask: null,
                                pattern: null,
                                variableNameForDefaultValue: null,
                                datasourceNameForComboOptions: null,
                                datasourceNameForDefaultValue: null,
                                order: 1030,
                                textToShowToSelect: null,
                                disabled: false,
                                defaultValue: null,
                                conditionalUserInputName: null,
                                conditionalUserInputValue: null,
                                comboData: [],
                                inputsThatDependsOnMe: [],
                                verification: 'NONE',
                                validationType: null,
                                facetValidation: null,
                                subDivision: false,
                                needReplication: false,
                                mustHidenIfUniqueResult: false,
                                minDate: null,
                                maxDate: null,
                                required: false,
                            },
                            group: 'Declaración Jurada',
                            name: 'customerUSCitizenFATCA',
                            datasourceName: 'userinput',
                            expression: null,
                            stateProcesses: [
                                {
                                    text: 'bnplAffidavitDataInput',
                                },
                            ],
                            id: 309,
                            stateProcessesNames: ['bnplAffidavitDataInput'],
                        },
                    ],
                    name: 'Declaración Jurada',
                },
            ],
        };

        return userInputs;
    },

    getUserInputFields: function (financialProductRequest) {
        const userInputs = {
            id: '64751a643f1a6e2fe4efcb90',
            name: 'complete_address_data',
            description: 'Formulario dinámico para el carga datos domicilio',
            type: 'MANUAL',
            groups: [
                //formElementGroups
                {
                    id: 13,
                    name: 'Datos personales',
                    order: 0,
                    label: 'Datos personales',
                    variables: [
                        //formElements
                        {
                            formElementType: 'MANUAL_COMBO',
                            id: 30,
                            name: 'customerCivilStatus',
                            description: 'Estado civil',
                            order: 10,
                            target: 'DEFAULT',
                            label: 'Estado civil',
                            elementConditionalDefinitions: [],
                            optionItems: [],
                            required: true,
                        },
                    ],
                },
                {
                    id: 14,
                    name: 'Domicilio',
                    order: 1,
                    label: 'Domicilio',
                    variables: [
                        {
                            formElementType: 'MANUAL_TEXT',
                            id: 31,
                            name: 'customerStreet',
                            description: 'Calle',
                            order: 10,
                            target: 'DEFAULT',
                            label: 'Calle',
                            elementConditionalDefinitions: [],
                            pattern: '^(?!\\s+$)[a-zA-Z.ñÑüÜ0-9 /()]{0,30}$',
                            required: true,
                            defaultValue: financialProductRequest.customerData.customerStreet,
                        },
                        {
                            formElementType: 'MANUAL_TEXT',
                            id: 32,
                            name: 'customerStreetNumber',
                            description: 'Altura',
                            order: 15,
                            target: 'DEFAULT',
                            label: 'Altura',
                            elementConditionalDefinitions: [],
                            pattern: '^[0-9]{0,6}$',
                            required: true,
                            defaultValue: financialProductRequest.customerData.customerStreetNumber,
                        },
                        {
                            formElementType: 'MANUAL_TEXT',
                            id: 33,
                            name: 'customerAddressFloor',
                            description: 'Piso',
                            order: 20,
                            target: 'DEFAULT',
                            label: 'Piso',
                            elementConditionalDefinitions: [],
                            required: false,
                            defaultValue: financialProductRequest.customerData.customerAddressFloor,
                        },
                        {
                            formElementType: 'MANUAL_TEXT',
                            id: 34,
                            name: 'customerAddressApartmentNumber',
                            description: 'Departamento',
                            order: 25,
                            target: 'DEFAULT',
                            label: 'Departamento',
                            elementConditionalDefinitions: [],
                            required: false,
                            defaultValue: financialProductRequest.customerData.customerAddressApartmentNumber,
                        },
                        {
                            formElementType: 'TYPEAHEAD',
                            id: 36,
                            name: 'customerAddressProvince',
                            description: 'Combo de provincias',
                            order: 40,
                            target: 'DEFAULT',
                            label: 'Seleccione la Provincia',
                            elementConditionalDefinitions: [],
                            optionItems: [],
                            required: true,
                            defaultValue: financialProductRequest.customerData.customerAddressProvince,
                        },
                        {
                            formElementType: 'TYPEAHEAD',
                            id: 37,
                            name: 'customerAddressCity',
                            description: 'Combo de Ciudades',
                            order: 45,
                            target: 'DEFAULT',
                            label: 'Seleccione la Ciudad',
                            elementConditionalDefinitions: [
                                {
                                    formElementId: 36,
                                    visibility: false,
                                },
                            ],
                            optionItems: [],
                            required: true,
                        },
                        {
                            formElementType: 'TYPEAHEAD',
                            id: 38,
                            name: 'customerAddressCP',
                            description: 'Combo de Codigos Postales',
                            order: 50,
                            target: 'DEFAULT',
                            label: 'Seleccione el codigo postal',
                            elementConditionalDefinitions: [
                                {
                                    formElementId: 37,
                                    visibility: false,
                                },
                            ],
                            optionItems: [],
                            required: true,
                        },
                    ],
                },
            ],
        };
        return userInputs;
    },
};

export default userInputComboService;
