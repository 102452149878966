import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import Slider from 'react-slick';
import offerSimulationService from '../services/offerSimulation.service';
import bnplService from '../services/bnpl.service';
import LandingTemplate from './shared/LandingTemplate';
import Loader from '../components/loader/Loader';
import { OfferTerms } from './shared/OfferTerms';
import '../styles/pages/offerSelection.scss';
import { bpmService } from '../services/bpm.service';
import userInputService from '../services/userInput.service';
import redirectUtils from '../components/bpm/useRedirect.jsx';

const useStyles = makeStyles((theme) => ({
    topBar: {
        backgroundColor: '#fafafa',
    },
    topBarSpacing: {
        margin: theme.spacing(2),
    },
    subtitle: {
        margin: theme.spacing(0, 5, 1, 5),
        textAlign: 'center',
    },
    continueButton: {
        margin: theme.spacing(5, 0, 5, 0),
    },
}));

export const OfferSelectionPage = (props) => {
    const classes = useStyles();
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const [toast, setToast] = useState(null);
    const { t } = useTranslation();
    const [offerSimulations, setOfferSimulations] = useState(null);
    const [selectedOfferSimulation, setSelectedOfferSimulation] = useState(null);
    const [commerceOperationData, setCommerceOperationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const redirect = redirectUtils();

    const settingsMobile = {
        dots: false,
        infinite: false,
        rows: 6,
        slidesPerRow: 2,
    };

    useEffect(() => {
        async function getCommerceOperationData() {
            try {
                setLoading(true);
                const result = (await bnplService.getById(financialProductRequest.commerceInfo.bnplOperationId)).data;
                setCommerceOperationData(result);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
            } finally {
                setLoading(false);
            }
        }
        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
            getCommerceOperationData();
        }
    }, [financialProductRequest]);

    useEffect(() => {
        async function getOfferSimulations() {
            setLoading(true);
            try {
                const state = (await userInputService.getState(financialProductRequest.requestContextData.customerAddressCountry, financialProductRequest.requestContextData.customerBankAccountProvince)).data;
                const offerSimulationRequest = {
                    submarketCode: financialProductRequest.requestContextData.submarketCode,
                    calculateCft: true,
                    commerceId: financialProductRequest.commerceInfo.commerceId,
                    provinceCode: state.code,
                    rateType: financialProductRequest.requestContextData.rateType,
                    interestRateTableName: financialProductRequest.requestContextData.interestRateTableName,
                    requestedAmount: commerceOperationData.cartAmount,
                    subProductId: 999999,
                    maxTerm: Number(financialProductRequest.requestContextData.loanMaximumTerm),
                    maxInstallmentAmount: Number(financialProductRequest.requestContextData.loanInstallmentMaximumAmount),
                    maxAmount: Number(financialProductRequest.requestContextData.loanMaximumAmount),
                    firstInstallmentDueDate: financialProductRequest.requestContextData.firstInstallmentDueDate ? financialProductRequest.requestContextData.firstInstallmentDueDate : undefined,
                };
                const offerSimulations = (await offerSimulationService.simulateOffers(offerSimulationRequest)).data;
                console.log('offerSimulations');
                console.log(offerSimulations);
                if(offerSimulations.length > 0) {
                    setSelectedOfferSimulation(offerSimulations[0]);
                    setOfferSimulations(offerSimulations);
                } else {
                    bpmService
                        .searchWorkflowBusinessInstancePendingTasks(processInstanceId)
                        .then(function (response) {
                            const taskId = response.data.result[0].taskId;
                            const payload = {
                                eventName: 'sendRejection',
                            };
                            bpmService.triggerTaskEvent(processInstanceId, taskId, payload)
                            .then(function (response) {
                                redirect.redirectFPRByStatus(businessKey, 'REJECTED');
                            }).catch(function (error) {
                                console.error(error);
                                setToast({ message: t(`WORKFLOW_ERROR.GENERAL.DESCRIPTION`) });
                            });
                        })
                        .catch(function (error) {
                            console.error(error);
                            setToast({ message: t(`WORKFLOW_ERROR.GENERAL.DESCRIPTION`) });
                        });
                }
            } catch (error) {
                if (error.response) {
                    const { cause, errorCode } = error.response.data;
                    setToast({ message: t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) });
                } else {
                    setToast({ message: t(`WORKFLOW_ERROR.GENERAL.DESCRIPTION`) });
                }
            } finally {
                setLoading(false);
            }
        }
        if (commerceOperationData) {
            getOfferSimulations();
        }
    }, [commerceOperationData]);

    const handleNextClicked = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const request = {
                inputMap: { selectedTerm: selectedOfferSimulation.installments },
            };
            await task.completeTask(request);
        } catch (error) {
            if (error.response && error.response.data) {
                const { errorCode } = error.response.data;
                setToast({ message: t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) });
            } else {
                setToast({ message: 'Algo salió mal al intentar avanzar con tu solicitud. Reintentá en unos segundos' });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleOfferSimulationClicked = (offerSimulation) => {
        setSelectedOfferSimulation(offerSimulation);
    };

    return (
        <LandingTemplate {...props} financialProductRequest={financialProductRequest}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {financialProductRequest && offerSimulations && commerceOperationData && (
                <>
                    <Grid container className={classes.topBar} component={Paper} elevation={0} square alignItems="center">
                        <Grid item className={classes.topBarSpacing}>
                            <h4>Hola {financialProductRequest.customerFullName}</h4>
                            <p>
                                {t(['offerSelectionPage.title.' + commerceOperationData.providerId, 'offerSelectionPage.title.default'])}<strong>{commerceOperationData.merchantOrderID}</strong> por{' '}
                                <strong>{numberToCurrencyText(commerceOperationData.cartAmount, 2)}</strong>.
                            </p>
                        </Grid>
                    </Grid>

                    <Container maxWidth="sm">
                        <Grid container>
                            <Grid item xs={0} sm={1}></Grid>
                            <Grid item xs={12} sm={10} container justifyContent="center">
                                <Grid item>
                                    <Box className="mb-1 mt-4">
                                        <h1>Elegí tu plan de cuotas:</h1>
                                    </Box>
                                </Grid>
                                <Grid item component={Box} className="SliderMobile" style={{ width: '99%' }}>
                                    <Slider {...settingsMobile}>
                                        {offerSimulations.map((offerSimulation, index) => (
                                            <Box
                                                component="div"
                                                className="boxSlider "
                                                key={index}
                                                onClick={(event) => handleOfferSimulationClicked(offerSimulation)}>
                                                <Box
                                                    component="div"
                                                    className={`boxSliderEffects ${offerSimulation === selectedOfferSimulation ? 'selected' : ''}`}>
                                                    <Grid container justifyContent="center" alignItems="center">
                                                        <Grid item>
                                                            <div className={`fs-18`}>{offerSimulation.installments} cuotas</div>
                                                            <div className="fs-18 fw-700">
                                                                de {numberToCurrencyText(offerSimulation.averageFee, 0)} *
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Slider>
                                </Grid>
                                <Grid item container className="mt-1">
                                    <OfferTerms offer={selectedOfferSimulation} />
                                </Grid>
                                <Grid item>
                                    <p className="fs-14 color-black mt-3 lh-20">
                                        {t(['offerSelectionPage.disclaimer.' + commerceOperationData.providerId, 'offerSelectionPage.disclaimer.default'])}
                                    </p>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Button
                                        variant="contained"
                                        className={classes.continueButton}
                                        disableElevation={true}
                                        fullWidth={true}
                                        color="primary"
                                        onClick={handleNextClicked}
                                        disabled={loading || !selectedOfferSimulation}>
                                        Continuar
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={0} sm={1}></Grid>
                        </Grid>
                    </Container>
                </>
            )}
        </LandingTemplate>
    );
};
