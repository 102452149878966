import TagManager from 'react-gtm-module';
import md5 from 'js-md5';
import { GTM_CODE, getEnv } from '../env';

const tagManagerArgs = {
    gtmId: `${getEnv(GTM_CODE)}`,
};
var _clientId = null;
var _testingGroup = null;

const eventTrackingService = {
    initialize: () => {
        TagManager.initialize(tagManagerArgs);
    },

    setClientId: (clientId) => {
        _clientId = md5(clientId).toUpperCase();
    },

    setTestingGroup: (testingGroup) => {
        _testingGroup = testingGroup;
    },
    // eventname (mandatory): the key of the event
    // label (optional): tag for the event
    // value (optional): if used, must be numeric
    // step (optional): indicator for funnel step. By default it uses the path from the url
    // method (optional): metodo por el que se accedió al evento
    trackEvent: (eventName, label, value, step, method) => {
        window.dataLayer.push({
            event: 'interaction',
            action: eventName,
            label: label,
            value: value,
            step: step || window.location.pathname,
            'onboarding-method': method,
            clientId: _clientId,
            'cd10': _testingGroup,
        });
    },
    // url: partial path of the call that failed
    // errorNumber: error code received either from backend or typicals (40X, 50X)
    // errorDescription: error description from browser o backend
    trackError: (url, errorNumber, errorDescription) => {
        window.dataLayer.push({
            event: 'error',
            action: 'error-service',
            target: url,
            targetProperties: errorDescription,
            value: errorNumber,
            clientId: _clientId,
            'cd10': _testingGroup,
        });
    },
    // errorNumber: error code received from TOC service
    // errorDescription: error description from browser o backend
    // instance: autocapture/liveness
    trackTocError: (errorNumber, errorDescription, instance) => {
        window.dataLayer.push({
            event: 'error',
            action: 'error-service',
            label: `toc_${errorNumber}`,
            target: instance,
            targetProperties: errorDescription,
            value: errorNumber,
            clientId: _clientId,
            'cd10': _testingGroup,
        });
    },
};

export default eventTrackingService;
