import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import financialProductsRequestsService from '../services/financialProductsRequestsService';

const useFinancialProductsRequest = (requestId) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [financialProductsRequest, setFinancialProductsRequest] = useState(null);

    const getFinancialProductsRequest = async (requestId) => {
        setLoading(true);
        try {
            const request = (await financialProductsRequestsService.getFinancialProductsRequest(requestId)).data;
            setFinancialProductsRequest(request);
            
        } catch (error) {
            // Ver el tema del manejo de errores. Vale hacerlo acá?            
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (requestId) {
            getFinancialProductsRequest(requestId);
        }
    }, []);

    return financialProductsRequest;
};

export default useFinancialProductsRequest;