import React, { useState } from 'react';
import { FormControl, FormHelperText} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const CustomTypeahead = ({ children, required, fullWidth, onChange, onInvalid, helperText, errorHelperText, ...props }) => {
    const [error, setError] = useState(false);

    const handleInvalid = (event) => {
        event.preventDefault();
        if (onInvalid) {
            onInvalid(event);
        }
        setError(true);
        console.log({error})
    };

    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
        setError(false);
    };

    return (
        <FormControl 
            fullWidth={fullWidth} 
            required={required}
            color="secondary"
        >
            <Autocomplete
            color="secondary"
                onInvalid={handleInvalid}
                onChange={handleChange}
                error={error}
                renderInput={(params) => React.isValidElement(children) && React.cloneElement(children, {
                    ...params,
                    color:"secondary",
                    error: error,
                    helperText: error ? errorHelperText : helperText
                })}
                {...props}
            />
        </FormControl>
    );
};
