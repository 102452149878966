import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pinInput: {
        margin: theme.spacing(1, 0, 1, 0),
    },
    title: {
        margin: theme.spacing(2, 0, 0, 0),
    },
}));

export const CellphonePinTextField = (props) => {
    const classes = useStyles();
    const { onChange, loading, mockedPin } = props;
    const [pin, setPin] = useState({ value: '' });

    const handlePinChange = (value) => {
        setPin({ value: value });
        if (value && value.length === 4) {
            onChange(value);
        } else {
            onChange(null);
        }
    };

    return (
        <>
            <Grid container>
                <Grid container item justifyContent="center">
                    <Typography variant="body1" className={classes.title}>
                        Código de validación
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container item justifyContent="center">
                    <Box className={classes.pinInput}>
                        <ReactCodeInput
                            name="pin"
                            type="number"
                            fields={4}
                            inputMode="numeric"
                            value={pin.value}
                            onChange={handlePinChange}
                            disabled={loading}
                            autoComplete="true"
                        />
                    </Box>
                </Grid>
                {mockedPin && 
                    <Grid container item justifyContent="center">
                        <Typography variant="body1">[Mock habilitado: {mockedPin}]</Typography>
                    </Grid>
                }
            </Grid>
        </>
    );
};
