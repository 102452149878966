import React, { useState, useEffect } from 'react';
import userInputService from '../../services/userInput.service';
import MenuItem from '@material-ui/core/MenuItem';
import { UNDEFINED_VALUE } from './userInputConstants';
import { CustomSelect } from '../customInputs/CustomSelect';
import Loader from '../loader/Loader';

const ComboUserInput = (props) => {
    //
    const {
        conditioningValue,
        parentConditioningValue,
        hashKey,
        stepName,
        userInput,
        onChange,
        defaultValue,
        defaultDisabled,
        parentElementConditionalValue,
        elementConditionalId,
        elementConditionalValue,
    } = props;
    const [comboList, setComboList] = useState(undefined);
    const [value, setValue] = useState(UNDEFINED_VALUE);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getComboData() {
            try {
                setLoading(true);
                let result = [];
                var totalPages = 1;
                var pageNumber = 0;
                while (pageNumber < totalPages) {
                    pageNumber++;
                    const partialResult = (
                        await userInputService.getComboList(userInput.name, parentElementConditionalValue, elementConditionalValue, pageNumber)
                    ).data;
                    result.push(...partialResult.result);
                    totalPages = partialResult.totalPages;

                    if (partialResult.result.length < 1000) {
                        return result;
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        if (elementConditionalId && elementConditionalValue) {
            userInput.elementConditionalDefinitions.forEach((conditionalDefinition) => {
                if (conditionalDefinition.formElementId === elementConditionalId) {
                    const comboData = getComboData().then((response) => {
                        setComboList(response);
                    });
                }
            });
        }
    }, [elementConditionalId, elementConditionalValue]);

    useEffect(() => {
        if (value && value !== UNDEFINED_VALUE) {
            onChange(userInput, value);
        }
    }, [value]);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        async function getComboData() {
            try {
                let result = [];
                var pageNumber = 0;
                var totalPages = 1;
                while (pageNumber < totalPages) {
                    pageNumber++;
                    const partialResult = (await userInputService.getComboList(userInput.name, null, null, pageNumber)).data;
                    result.push(...partialResult.result);
                    totalPages = partialResult.totalPages;
                    if (partialResult.result.length < 1000) {
                        setComboList(result);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        if (userInput && userInput.elementConditionalDefinitions.length === 0) {
            getComboData();
        }
    }, [userInput]);

    useEffect(() => {
        if (comboList) {
            if (defaultValue) {
                setValue(defaultValue);
            } else {
                comboList.forEach((item) => {
                    if (item.selected) {
                        setValue(item.id);
                    }
                });
            }
        }
    }, [comboList]);

    const handleComboChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <>
            {comboList ? (
                <CustomSelect
                    fullWidth={true}
                    required={userInput.required}
                    name={userInput.name}
                    label={userInput.label}
                    onChange={handleComboChange}
                    value={value}
                    disabled={comboList.length < 1 || defaultDisabled}
                    errorHelperText="Requerido">
                    {comboList.map((item) => (
                        <MenuItem
                            id={userInput.name === 'customerCivilStatus' ? item.code : item.id}
                            key={userInput.name === 'customerCivilStatus' ? item.code : item.id}
                            value={userInput.name === 'customerCivilStatus' ? item.code : item.id}>
                            {item.description ? item.description : item.code}
                        </MenuItem>
                    ))}
                    {comboList.length > 1 && <MenuItem value={UNDEFINED_VALUE}>Seleccione alguna de las opciones</MenuItem>}
                </CustomSelect>
            ) : (
                <Loader loading={loading} />
            )}
        </>
    );
};

export default ComboUserInput;
