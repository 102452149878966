import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { LoginPage } from './pages/LoginPage';
import { RejectedPage } from './pages/RejectedPage';
import { LandingPage } from './pages/LandingPage';
import { BankAccountProvinceDataInput } from './pages/BankAccountProvinceSelectionPage';
import { OfferSelectionPage } from './pages/OfferSelectionPage';
import { FrontDocumentSelection } from './pages/FrontDocumentSelectionPage';
import { BackDocumentSelection } from './pages/BackDocumentSelectionPage';
import { RestartDocumentsUpload } from './pages/RestartDocumentsUploadPage';
import { SelfieDocumentSelection } from './pages/SelfieDocumentSelectionPage';
import { PersonalAddresDataInput } from './pages/PersonalAddresDataInputPage';
import { AffidavitDataInput } from './pages/AffidavitDataInputPage';
import { DisclosurePage } from './pages/DisclosurePage';
import { SuccessPage } from './pages/SuccessPage';
import { BranchOfficeBankAccountDataInput } from './pages/BranchOfficeSelectionPage';
import { RedirectFromMinihash } from './components/deviceCommunication/redirectFromMinihash';
import { WaitPage } from './pages/waitPage';
import { NoRetryable } from './pages/NoRetryablePage';
import { CommercePrequalificationApprovedPage } from './pages/commercePrequalifications/CommercePrequalificationApprovedPage';
import { CommercePrequalificationRejectedPage } from './pages/commercePrequalifications/CommercePrequalificationRejectedPage';
import { CommercePrequalificationStartPage } from './pages/commercePrequalifications/CommercePrequalificationStartPage';
import theme from './styles/resources/themes/theme';
import eventTrackingService from './services/eventtracking.service';

eventTrackingService.initialize();

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route path="/noRetryable/:errorText/:bnplOperationId" render={(props) => <NoRetryable {...props} />} />{' '}
                    <Route path="/noRetryable/:errorText" render={(props) => <NoRetryable {...props} />} />{' '}
                    <Route
                        path="/bnplWait/:businessKey"
                        render={(props) => <WaitPage {...props} taskDefinitionId="bnplWait" title="Estamos evaluando tu oferta" />}
                    />{' '}
                    <Route
                        path="/bnplSelectBankAccountProvince/:businessKey"
                        render={(props) => (
                            <BankAccountProvinceDataInput
                                {...props}
                                taskDefinitionId="bnplSelectBankAccountProvince"
                                title="Selección de Provincia de radicación de cuenta"
                            />
                        )}
                    />{' '}
                    <Route
                        path="/bnplOfferSelection/:businessKey"
                        render={(props) => <OfferSelectionPage {...props} taskDefinitionId="bnplOfferSelection" title="Selección de Oferta" />}
                    />{' '}
                    <Route
                        path="/bnplDocumentsUploadFront/:businessKey"
                        render={(props) => (
                            <FrontDocumentSelection
                                {...props}
                                taskDefinitionId="bnplDocumentsUploadFront"
                                title="Sacale una foto al frente de tu DNI"
                            />
                        )}
                    />{' '}
                    <Route
                        path="/bnplDocumentsUploadBack/:businessKey"
                        render={(props) => (
                            <BackDocumentSelection {...props} taskDefinitionId="bnplDocumentsUploadBack" title="Sacale una foto al dorso de tu DNI" />
                        )}
                    />{' '}
                    <Route
                        path="/restartDocumentsUpload/:businessKey"
                        render={(props) => (
                            <RestartDocumentsUpload
                                {...props}
                                taskDefinitionId="restartDocumentsUpload"
                                title="Hubo un error en la carga de documentos"
                            />
                        )}
                    />{' '}
                    <Route
                        path="/bnplDocumentsUploadSelfie/:businessKey"
                        render={(props) => (
                            <SelfieDocumentSelection
                                {...props}
                                taskDefinitionId="bnplDocumentsUploadSelfie"
                                title="Necesitamos confirmar que sos la misma persona que indica tu documento"
                            />
                        )}
                    />{' '}
                    <Route
                        path="/bnplPersonalAddressDataInput/:businessKey"
                        render={(props) => (
                            <PersonalAddresDataInput {...props} taskDefinitionId="bnplPersonalAddressDataInput" title="Completá tus datos" />
                        )}
                    />{' '}
                    <Route
                        path="/bnplAffidavitDataInput/:businessKey"
                        render={(props) => <AffidavitDataInput {...props} taskDefinitionId="bnplAffidavitDataInput" title="Datos adicionales" />}
                    />{' '}
                    <Route
                        path="/bnplSelectBranchOffice/:businessKey"
                        render={(props) => (
                            <BranchOfficeBankAccountDataInput
                                {...props}
                                taskDefinitionId="bnplSelectBranchOffice"
                                title="Seleccioná la sucursal para la apertura de cuenta"
                            />
                        )}
                    />{' '}
                    <Route
                        path="/bnplDisclosure/:businessKey"
                        render={(props) => <DisclosurePage {...props} taskDefinitionId="bnplDisclosure" title="Firma de contrato" />}
                    />{' '}
                    <Route
                        path="/success/:businessKey"
                        render={(props) => <SuccessPage {...props} taskDefinitionId="BnplSuccessPage" title="Exito!!!!" />}
                    />{' '}
                    <Route path="/u/:minHash/orig/:orig" render={(props) => <RedirectFromMinihash {...props} />} />{' '}
                    <Route path="/u/:minHash" render={(props) => <RedirectFromMinihash {...props} />} />{' '}
                    <Route path="/st/:minHash" render={(props) => <RedirectFromMinihash {...props} redirectTo="contract" />} />{' '}
                    <Route path="/customer-identification" render={(props) => <LoginPage {...props} />} />
                    <Route path="/rejected/:businessKey" render={(props) => <RejectedPage {...props} />} />
                    <Route path="/commerce-prequalifications/approved" render={(props) => <CommercePrequalificationApprovedPage {...props} />} />{' '}
                    <Route path="/commerce-prequalifications/rejected" render={(props) => <CommercePrequalificationRejectedPage {...props} />} />{' '}
                    <Route path="/commerce-prequalifications/start" render={(props) => <CommercePrequalificationStartPage {...props} />} />{' '}
                    <Route path="/" render={(props) => <LandingPage {...props} />} />{' '}
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

export default App;
