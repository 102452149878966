import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';

const verificationCodeService = {
    createVerificationCode: function (cuit, phoneNumber, token) {
        return axiosInstance.post(`${RESTAPI_VERSION}/macro/cellphone-pin-challenges`, {
            customerTaxId: cuit,
            cellphone: phoneNumber,
        });
    },
};

export default verificationCodeService;
