import React, { useState, useEffect } from 'react';
import { UNDEFINED_VALUE } from './userInputConstants';
import Map from '../utils/Map';
import { Grid, Box, Button, ButtonGroup, List, TextField, ListItem, Paper, InputAdornment, makeStyles } from '@material-ui/core';
import '../../styles/components/userInputs.scss';
import { GOOGLE_MAP_API_KEY, getEnv } from '../../env';
import userInputService from '../../services/userInput.service';
import useFinancialProductsRequests from '../../hooks/useFinancialProductsRequest';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    btn: {
        borderRadius: '4px',
    },
    btnGroup: {
        width: '90vw',
    },
}));

const MapUserInput = (props) => {
    const { conditioningValue, parentConditioningValue, hashKey, stepName, userInput, onChange } = props;
    const [markerList, setMarkerList] = useState(null);
    const [value, setValue] = useState(UNDEFINED_VALUE);

    const financialProductRequest = useFinancialProductsRequests(hashKey);

    const mapURL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=' + getEnv(GOOGLE_MAP_API_KEY);
    const [showMap, setShowMap] = useState(true);
    const [description, setDescription] = useState(null);
    const [code, setCode] = useState(null);
    const [address, setAddress] = useState(null);
    const [filteredMarkerList, setFilteredMarkerList] = useState(null);
    const [checked, setChecked] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    const classes = useStyles();

    const filterData = (e) => {
        const query = e.target.value;
        const filtered = markerList.filter(
            (d) =>
                d.description
                    .replace(/\.*\s*/g, '')
                    .toLowerCase()
                    .includes(query.replace(/\.*\s*/g, '').toLowerCase()) ||
                d.address.street
                    .replace(/\.*\s*/g, '')
                    .toLowerCase()
                    .includes(query.replace(/\.*\s*/g, '').toLowerCase())
        );
        setFilteredMarkerList(filtered);
        setSearchValue(query);
        setChecked(null);
        onChange(userInput, null);
    };

    const handleToggle = (e) => {
        setChecked(e.target.id);
        onChange(userInput, e.target.id);
    };

    useEffect(() => {
        if (value) {
            onChange(userInput, value);
        }
        setFilteredMarkerList(markerList);
    }, [value, markerList]);

    useEffect(() => {
        async function getComboData() {
            try {
                let result = [];
                var pageNumber = 0;
                var totalPages = 1;
                while (pageNumber < totalPages) {
                    pageNumber++;
                    const partialResult = (
                        await userInputService.getComboList(
                            userInput.name,
                            financialProductRequest.fundingEntityInfo.fundingEntityId,
                            financialProductRequest.requestContextData.customerBankAccountProvince,
                            pageNumber
                        )
                    ).data;
                    result.push(...partialResult.result);
                    totalPages = partialResult.totalPages;
                    if (partialResult.result.length < 1000) {
                        setMarkerList(result);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        if (financialProductRequest && userInput && userInput.elementConditionalDefinitions.length === 0) {
            getComboData();
        }
    }, [userInput, financialProductRequest]);

    const handleMarkerChange = (userInput, selectedMarker) => {
        if (selectedMarker !== null) {
            onChange(userInput, selectedMarker.id);
        } else {
            onChange(userInput, null);
            setValue(null);
        }

        if (selectedMarker && selectedMarker !== null) {
            setValue(selectedMarker.id);
            setAddress(selectedMarker.address);
            setDescription(selectedMarker.description);
            setCode(selectedMarker.code);
        }
    };

    const handleShowMap = () => {
        setValue(null);
        setShowMap(true);
    };

    const handleShowList = () => {
        setValue(null);
        setShowMap(false);
    };

    const getVariantMap = () => {
        return showMap ? 'contained' : 'outlined';
    };

    const getVariantList = () => {
        return !showMap ? 'contained' : 'outlined';
    };

    const clearValue = () => {
        setSearchValue('');
        setFilteredMarkerList(markerList);
    };

    return (
        <Grid className="mt-1">
            <Grid item xs={12} sm={12}>
                <div className={classes.btnGroup}>
                    <ButtonGroup disableElevation color="secondary" fullWidth size="large">
                        <Button className={classes.btn} onClick={handleShowMap} variant={getVariantMap()}>
                            Mapa
                        </Button>
                        <Button className={classes.btn} onClick={handleShowList} variant={getVariantList()}>
                            Listado
                        </Button>
                    </ButtonGroup>
                </div>
                <Box className="mt-1"></Box>
                {showMap && userInput && financialProductRequest && markerList ? (
                    <>
                        <Map
                            googleMapURL={mapURL}
                            onChange={handleMarkerChange}
                            markerList={markerList}
                            userInput={userInput}
                            containerElement={<div style={{ aspectRatio: '3 / 2', width: '90vw', marginBottom: '40px', marginTop: '20px' }} />}
                            mapElement={<div style={{ aspectRatio: '3 / 2', width: '90vw', borderRadius: '5px' }} />}
                            loadingElement={<p>Cargando...</p>}
                        />

                        {value && (
                            <Grid item xs={12}>
                                <Box className="mt-2"> </Box>
                                <div className="selecteddiv flex center ">
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
                                            <>
                                                <p class="fw-600 color-dark-gray">Sucursal seleccionada</p>
                                                <p class="fw-400 color-dark-gray">
                                                    {description} ({code}), {address.street}
                                                </p>
                                            </>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )}
                    </>
                ) : filteredMarkerList ? (
                    <Box sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                        <TextField
                            fullWidth={true}
                            id="search-bar"
                            value={searchValue}
                            onChange={filterData}
                            label="Buscá por nombre o dirección"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchValue == '' ? <SearchIcon /> : <ClearIcon onClick={clearValue} />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Paper style={{ height: '60vh', overflow: 'auto', boxShadow: 'none' }}>
                            <List>
                                {filteredMarkerList.map((item) => (
                                    <ListItem>
                                        <div className="flex" key={item.id}>
                                            <input
                                                onChange={handleToggle}
                                                type="radio"
                                                id={item.id}
                                                name={item.description}
                                                value={item.id}
                                                checked={checked == item.id}
                                                disabled={false}
                                            />

                                            {'  '}
                                            <div className="ml-05">
                                                <p>
                                                    <strong>
                                                        {item.description} ({item.code})
                                                    </strong>
                                                    <br />
                                                    {item.address.street}
                                                </p>
                                            </div>
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Box>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default MapUserInput;
