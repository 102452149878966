import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v2';
const OPERATIONS_PATH = '/bnpl-operations';

const bnplService = {

    getById: function (id) {
        return axiosInstance.get(`${RESTAPI_VERSION}${OPERATIONS_PATH}/${id}`);
    }

};

export default bnplService;