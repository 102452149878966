import { Grid, Container, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './../../styles/pages/commercePrequalificationApprovedPage.scss';
import mainImage from './../../assets/HandTeamMacro.svg';
import { numberToCurrencyText, numberToPercentageText } from './../../components/utils/numberUtils';
import LandingTemplate from '../shared/LandingTemplate';

export const CommercePrequalificationApprovedPage = (props) => {
    const location = useLocation();
    const customerData = location.state?.customerData;
    const prequalificationData = location.state?.prequalificationData;
    const redirectUrl = location.state?.redirectUrl;

    useEffect(() => {
        localStorage.clear();
    }, []);

    const redirectToCommerceStore = (e) => {
        e.preventDefault();
        window.location.replace(redirectUrl);
    };

    const offerNeedsRightHandSideBorder = (offerSimulations, index) => {
        return (index + 1) % 4 != 0 && index + 1 != offerSimulations.length;
    };

    return (
        <LandingTemplate {...props}>
            <Container maxWidth="sm">
                <Grid item container direction="row" style={{ zIndex: '1' }}>
                    <Grid item xs={12}>
                        <img src={mainImage} className="prequalification-approved-image" alt="Macro Hand Team" />
                    </Grid>
                    <Grid item xs={11}>
                        <h2 className="prequalification-approved-customer-name mt-2">{customerData.customerFullName.toLowerCase()}</h2>
                        <h1 className="prequalification-approved-title">
                            ¡Tenemos{' '}
                            <span className="prequalification-approved-amount">{numberToCurrencyText(prequalificationData.loanMaximumAmount)}</span>{' '}
                            disponibles para vos!
                        </h1>
                    </Grid>
                    {prequalificationData.offerSimulations.length > 0 && (
                        <>
                            <Grid item container xs={12} className="mt-2">
                                <p className="prequalification-approved-subtitle mt-1">Mirá en cuántas cuotas podes pagarlo.</p>
                                {prequalificationData.offerSimulations.map((offerSimulation, index) => (
                                    <Grid item xs={3} key={offerSimulation.installments}>
                                        <Box
                                            component="div"
                                            className={`mt-1 ${
                                                offerNeedsRightHandSideBorder(prequalificationData.offerSimulations, index)
                                                    ? 'prequalification-approved-inner-offer'
                                                    : ''
                                            }`}>
                                            <Box component="div">
                                                <Grid container justifyContent="center" alignItems="center">
                                                    <Grid item>
                                                        <div className="prequalification-approved-offer-top">
                                                            <span className="prequalification-approved-offer-top-emphasis">
                                                                {offerSimulation.installments}
                                                            </span>{' '}
                                                            cuotas de
                                                        </div>
                                                        <div className="prequalification-approved-offer-bottom">
                                                            {numberToCurrencyText(offerSimulation.amount, 0)}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item container>
                                <Grid container>
                                    <Grid>
                                        <p className="prequalification-approved-rates mt-2">
                                            TNA: {numberToPercentageText(prequalificationData.offerSimulations[0].nominalAnnualRate, 2)}
                                        </p>
                                    </Grid>
                                    <Grid>
                                        <p className="prequalification-approved-rates-separator mt-2">{'-'}</p>
                                    </Grid>
                                    <Grid>
                                        <p className="prequalification-approved-rates mt-2">
                                            TEA: {numberToPercentageText(prequalificationData.offerSimulations[0].effectiveAnnualRate, 2)}
                                        </p>
                                    </Grid>
                                    <Grid>
                                        <p className="prequalification-approved-rates-separator mt-2">{'-'}</p>
                                    </Grid>
                                    <Grid>
                                        <p className="prequalification-approved-rates mt-2">
                                            <strong>
                                                CFTEA: {numberToPercentageText(prequalificationData.offerSimulations[0].totalFinancialCost, 2)}
                                            </strong>
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <p className="prequalification-approved-offer-disclaimer mt-1">
                                    (*) El monto de las cuotas es estimativo, pudiendo variar entre días laborables y fines de semana o feriados. El
                                    IVA ya se encuentra incluido dentro del valor estimado. La cuota explicitada equivale a la cuota promedio a lo
                                    largo de todo el préstamo, por lo cual la primera cuota será mayor y la última cuota será menor al valor mostrado.
                                </p>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <p className="prequalification-approved-call-to-action mt-2">
                            Volvé a la tienda para realizar tu compra y elegí préstamos Banco Macro en tu forma de pago.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" className="mt-3 mb-3">
                        {redirectUrl && (
                            <Button variant="contained" disableElevation={true} fullWidth={true} color="primary" onClick={redirectToCommerceStore}>
                                {t(['commercePrequalificationApprovedPage.goBackButton.' + commerceOperationData.providerId, 'commercePrequalificationApprovedPage.goBackButton.default'])}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </LandingTemplate>
    );
};
