import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const PROCESS_NAME = 'macro/toc-sessions';

export const tocService = {
    getUploadToken: (id) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/${PROCESS_NAME}`, {
            financialProductRequestId: id
        });
    }
};

export default tocService;