import axios from 'axios';
import { ONSCORE_LOCAL_BASE_URL, ONSCORE_LOCAL_JWT, getEnv } from '../env';

// In this file we can create common headers and add interceptors

const axiosLocalInstance = axios.create({
    baseURL: `${getEnv(ONSCORE_LOCAL_BASE_URL)}`,
    timeout: 120000,
    withCredentials: false,
});

async function refreshAccessToken() {
    try {
        const refreshPayload = {
            refreshToken: localStorage.getItem('refresh_token')
        };
        const authResult = await authenticationService.refresh(refreshPayload);
        
        
        
        if(authResult && authResult.data) {
            localStorage.setItem('access_token', authResult.data.access_token);
            localStorage.setItem('refresh_token', authResult.data.refresh_token);
        }
        return authResult;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

axiosLocalInstance.interceptors.request.use(function (request) {
    request.headers['X-TENANT'] = 'macro';
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if(!request.url.includes('refresh-token')) {
        request.headers.authorization = `Bearer ${getEnv(ONSCORE_LOCAL_JWT)}`;
    }
    
    return request;
});

axiosLocalInstance.interceptors.response.use(
    
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        // log error event to analytics
        const errorCode = error.response.data.errorCode ? error.response.data.errorCode : error.message;
        eventTrackingService.trackError(error.config.url, error.response.status, errorCode);
        if (error.response.status === 401 && !error.request.responseURL.includes('authentication/refresh-token') && !error.request.responseURL.includes('authentication/login') && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const response = await refreshAccessToken();
                const accessToken = localStorage.getItem('access_token');
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return await axios(originalRequest);
            } catch (error_1) {
                if (error_1.response.status === 401 || error_1.response.status === 403) {
                    window.location = `${window.location.origin}/noRetryable/EXPIRED`;
                }
            }
          
        } else {
            if (error.response.status === 401 || error.response.status === 403) {
                window.location = `${window.location.origin}/noRetryable/EXPIRED`;
            }
        }
        return Promise.reject(error);
    }
);

export default axiosLocalInstance;
