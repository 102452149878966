import { Grid, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './../../styles/pages/commercePrequalificationApprovedPage.scss';
import errorImg from './../../assets/Error.svg';
import LandingTemplate from './../shared/LandingTemplate';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(5, 0, 0, 0),
        textAlign: 'center',
    },
    description: {
        margin: theme.spacing(5, 0, 5, 0),
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing(5, 0, 5, 0),
    },
}));

export const CommercePrequalificationRejectedPage = (props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const classes = useStyles();
    const redirectUrl = location.state?.redirectUrl;

    useEffect(() => {
        localStorage.clear();
    }, []);

    const redirectToCommerceStore = (e) => {
        e.preventDefault();
        window.location.replace(redirectUrl);
    };

    return (
        <LandingTemplate {...props}>
            <Container maxWidth="sm">
                <Grid item xs={12} container justifyContent="center">
                    <img src={errorImg} className="mt-4" alt="" />
                </Grid>
                <Grid container>
                    <Grid item xs={false} sm={1}></Grid>
                    <Grid item xs={12} sm={10} container>
                        <Grid item container justifyContent="center">
                            <Grid item>
                                <h1 className={classes.title}>{t(`pages.commerce-prequalifications-rejected.main-title`)}</h1>
                                <h3 className={classes.description}>{t(`pages.commerce-prequalifications-rejected.main-text`)}</h3>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            {redirectUrl && (
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    fullWidth={true}
                                    color="primary"
                                    disableElevation={true}
                                    onClick={redirectToCommerceStore}>
                                    {t(['commercePrequalificationRejectedPage.goBackButton.' + commerceOperationData.providerId, 'commercePrequalificationRejectedPage.goBackButton.default'])}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </LandingTemplate>
    );
};
