import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';

const minifiedHashService = {

    getMinifiedRequestUrlFromMinhash: (minHash) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/minified-hashes-definitions/?hashId=${minHash}&pageNumber=1&pageSize=1`);
    },
    
};

export default minifiedHashService;