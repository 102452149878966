import React, { useState } from 'react';
import { Button, Grid, Box, makeStyles, Container, Paper } from '@material-ui/core';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import MacroStepPage from './shared/MacroStepPage';
import { useEffect } from 'react';
import useFinancialProductsRequests from '../hooks/useFinancialProductsRequest';
import Loader from '../components/loader/Loader';
import successImg from '../assets/success-bnpl.svg';
import bnplService from '../services/bnpl.service';

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#fff',
        marginTop: '20px',
        textAlign: 'center',
        fontSize: '1.55rem',
    },
    customerName: {
        color: '#fff',
        marginBottom: '20px',
        textAlign: 'center',
        fontSize: '1.55rem',
    },
    orderSummary: {
        color: '#fff',
        margin: theme.spacing(4, 3, 2, 3),
        textAlign: 'center',
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
    },
    disclosureAcceptedText: {
        textAlign: 'center',
        fontSize: '1rem',
    },
    continueButton: {
        marginTop: '40px',
    },
    orderId: {
        margin: theme.spacing(0, 0, 5, 0),
        textAlign: 'center',
    },
    orderLineItem: {
        margin: theme.spacing(0, 0, 0, 0),
    },
    paper: {
        margin: theme.spacing(1, 1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '5%',
    },
    loanDetails: {
        margin: theme.spacing(0, 2, 0, 2),
        color: '#515151',
        fontSize: '1rem',
        lineHeight: '1.9rem',
        borderBottom: '1px solid #ddd',
    },
    loanDetailsBottom: {
        margin: theme.spacing(0, 2, 0, 2),
        color: '#515151',
        fontSize: '1rem',
        lineHeight: '1.9rem',
    },
    loanDetailsContainer: {
        margin: theme.spacing(4, 0, 4, 0),
    },
    orderNotice: {
        color: '#fff',
        fontSize: '0.9rem',
        lineHeight: '1.1rem',
        textAlign: 'center',
    },
}));

const MAX_WAIT_TIME = 15;

export const SuccessPage = (props) => {
    const { businessKey } = props.match.params;
    const { t } = useTranslation();
    const financialProductRequest = useFinancialProductsRequests(businessKey);
    const [toast, setToast] = useState(null);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [bnplOperationId, setBnplOperationId] = useState(null);
    const [bnplOperation, setBnplOperation] = useState(null);
    const [retryIntervalId, setRetryIntervalId] = useState(null);
    const [showInterval, setShowInterval] = useState(false);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);

    useEffect(() => {
        if (financialProductRequest) {
            setBnplOperationId(financialProductRequest.requestContextData.ecommerceIntegrationRequestId);
        }
    }, [financialProductRequest]);

    useEffect(() => {
        if (bnplOperationId) {
            getBnplOperation(bnplOperationId);
            setShowInterval(true);
            const intervalId = setInterval(() => {
                setSeconds((previousSeconds) => {
                    return previousSeconds - 1;
                });
            }, 1000);
            setRetryIntervalId(intervalId);
        }
    }, [bnplOperationId]);

    const getBnplOperation = async (id) => {
        try {
            setLoading(true);
            const response = await bnplService.getById(bnplOperationId);
            setBnplOperation(response.data);
        } catch (error) {
            setToast({ message: t(`WORKFLOW_ERROR.GENERAL.DESCRIPTION`) });
        } finally {
            setLoading(false);
        }
    };

    const onStateChange = () => {
        if (seconds <= 0 && showInterval) {
            setRetryIntervalId(null);
            setShowInterval(false);

            window.location.replace(bnplOperation.merchantReturnSuccessURL);
        }
    };

    onStateChange();

    return (
        <MacroStepPage {...props} financialProductRequest={financialProductRequest}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {financialProductRequest && bnplOperation && (
                <Container maxWidth="sm">
                    <Grid item xs={12} container justifyContent="center">
                        <img src={successImg} className="mt-4" alt="" />
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <Grid item component={Box}>
                            <h1 className={classes.title}>Felicitaciones</h1>
                            <h1 className={classes.customerName}>{financialProductRequest.customerFullName}</h1>
                        </Grid>
                        <Grid item>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <p className={classes.orderSummary}>{t(['successPage.operationCompleteMessage.' + bnplOperation.providerId, 'successPage.operationCompleteMessage.default'])}</p>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <p className={classes.orderNotice}>{t(['successPage.operationCompleteDescription.' + bnplOperation.providerId, 'successPage.operationCompleteDescription.default'])}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Box className="mt-2 mb-2">
                                {retryIntervalId && showInterval && (
                                    <i className={classes.orderNotice}>Te redirigiremos a la {t(['successPage.redirectionTarget.' + bnplOperation.providerId, 'successPage.redirectionTarget.default'])} en {seconds} segundos.</i>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </MacroStepPage>
    );
};
