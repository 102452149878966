import { makeStyles } from '@material-ui/core';
import backgroundImage from '../../assets/background-bnpl.svg';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(90vh - 80px)',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        height: '80px',
    },
    toolbarLogo: {
        height: '35px',
    },
    link: {
        color: '#092f57',
    },
    image: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#092f57',
        backgroundSize: '100%',
        backgroundPosition: 'bottom right',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    topBar: {
        backgroundColor: '#fafafa',
    },

    subHeaderTitle: {
        background: '#FFFFFF',
        borderBottom: '1px solid #ddd',
        paddingRight: 10,
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
    },
    timeoutContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    subtitle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    subtitleChild: { width: '80%', maxWidth: '600px', alignSelf: 'center' },
    modalBody: {
        height: '340px',
        borderRadius: '5px',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px 30px',
        textAlign: 'center',
    },
    modalTitle: { lineHeight: '20px', marginBottom: 20, alignSelf: 'center' },
    modalDesc: { lineHeight: '20px', marginBottom: 20, textAlign: 'center' },
    modalBtn: { width: '180px', marginBottom: 10, height: '40px', alignSelf: 'center' },
}));
