import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import termService from './../../services/term.service';
import { isMobile } from 'react-device-detect';

import Toaster from './../../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';

import Terms_Html from './../../components/terms/terms.jsx';

const TermsModal = (props) => {
    const [termsHtml, setTermsHtml] = useState('');
    const classes = useStyles();

    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.termsUrl) {
            getTermsHtml();
        }
    }, [props.termsUrl]);

    const getTermsHtml = async () => {
        try {
            if(!props.staticTerms) {
                const termsHtml = (await termService.getTermsHtml(props.termsUrl)).data;
                setTermsHtml(termsHtml);
            }
            
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`) || 'Error al aceptar los terminos y condiciones');
            } else {
                setMessage(error.message);
            }
            setOpen(true);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (props.open === true) {
            setOpen(true);
        } else if (props.open === false) {
            setOpen(false);
        }
    }, [props.open]);

    return (
        <div>
            <Modal open={open} onClose={props.onClose} aria-labelledby="Términos y condiciones" aria-describedby="Términos y condiciones">
                <div className={classes.paper}>
                    <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                    <Container maxWidth="sm">
                        <Grid container>
                            <Grid item xs={12}>
                                <Box overflow="visible">
                                    <h2 align="center" className={classes.tTitle} paragraph={true}>
                                        {'Términos y condiciones'}
                                    </h2>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className={classes.body}>
                            {props.staticTerms ? <Terms_Html/> : <div dangerouslySetInnerHTML={{ __html: termsHtml }}></div>}
                            
                                
                            </Grid>
                            <Grid item container justifyContent="space-between" alignItems="center" xs={12} className={classes.actionBar} spacing={1}>
                                <Grid item xs={5}>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.onReject();
                                        }}
                                        color="default"
                                        className={classes.defaultButton}
                                        fullWidth={true}>
                                        Rechazar
                                    </Button>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.onAccept();
                                        }}
                                        color="primary"
                                        className={classes.primaryButton}
                                        autoFocus
                                        fullWidth={true}>
                                        Aceptar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Modal>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: 520,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: 0,
        borderRadius: 8,
        maxWidth: '80%',
    },
    body: {
        height: 380,

        overflowY: 'auto',
        fontSize: '14px',
        color: '#212121',
    },
    actionBar: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    defaultButton: {
        color: '#9b9b9b',
        backgroundColor: '#ffffff',
        borderColor: '#9b9b9b',
        borderWidth: '1px',
        borderStyle: 'solid',
        fontSize: '14px',
    },
    primaryButton: {
        color: '#ffffff',
        backgroundColor: '##FF4452',
        fontSize: '14px',
    },
    title: {
        fontSize: '18px',
        color: '#092f57',
    },
}));

export default TermsModal;
