import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const PROCESS_INSTANCES_PATH = '/workflow-business-instances';
const FINANCIAL_PRODUCT_REQUEST_PATH = 'financial-product-request-files';

export const bpmService = {
    createWorkflowBusinessInstance: (payload) => {
        return axiosInstance.post(`${RESTAPI_VERSION}${PROCESS_INSTANCES_PATH}`, payload);
    },
    searchWorkflowBusinessInstancePendingTasks: (processInstanceId, filter) => {
        return axiosInstance.get(`${RESTAPI_VERSION}${PROCESS_INSTANCES_PATH}/${processInstanceId}/pending-tasks`, { params: filter });
    },
    resolveWorkflowBusinessInstanceTask: (processInstanceId, taskId, payload) => {
        return axiosInstance.put(`${RESTAPI_VERSION}${PROCESS_INSTANCES_PATH}/${processInstanceId}/tasks/${taskId}`, payload);
    },
    createFinancialProductsRequestFile: (params) => {
        const formData = new FormData();
        formData.append('file', params.file, params.document + '.jpg');
        formData.append('taskId', params.taskId);
        formData.append('documentName', params.document);
        formData.append('documentType', params.document);
        formData.append('processInstanceId', params.processInstanceId);
        formData.append('financialProductRequestId', params.financialProductsRequestId);
        return axiosInstance.post(`${RESTAPI_VERSION}/${FINANCIAL_PRODUCT_REQUEST_PATH}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    triggerTaskEvent: (processInstanceId, taskId, payload) => {
        return axiosInstance.post(`${RESTAPI_VERSION}${PROCESS_INSTANCES_PATH}/${processInstanceId}/tasks/${taskId}/events`, payload);
    },
};

export default bpmService;