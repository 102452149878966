import { DialogTitle, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    
    modalBody: {
        height: '340px',
        borderRadius: '5px',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px 30px',
        textAlign: 'center',
    },
    modalTitle: { lineHeight: '20px', marginBottom: 20, alignSelf: 'center' },
    modalDesc: { fontSize: 14, lineHeight: '20px', marginBottom: 20, textAlign: 'center' },
    modalBtn: { width: '180px', marginBottom: 10, height: '40px', alignSelf: 'center' },
}));

export const SimplePopup = (props) => {
    const { onClosePopup, onConfirm, isOpen, title, description } = props;
    const classes = useStyles();

    return (
        <Dialog onClose={onClosePopup} aria-labelledby="help-dialog-title" open={isOpen}>
            <DialogTitle className={classes.modalTitle} id="help-dialog-title" onClose={onClosePopup}>
                <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: '20px' }}>
                    {title}
                </div>
            </DialogTitle>
            <DialogContent className={classes.modalDesc}>
                {description}
            </DialogContent>
            <DialogActions>
                <Button className={classes.modalBtn} variant="outlined" onClick={onClosePopup} color="primary" style={{ width: '100%' }}>
                    No
                </Button>
                <Button className={classes.modalBtn} variant="contained" onClick={onConfirm} color="primary" style={{ width: '100%' }}>
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    
        );

};
export default SimplePopup;