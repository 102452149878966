import axiosInstance from './axios.config.onscore';
import axiosLocalInstance from './axios.config.local';

const RESTAPI_VERSION = '/v1';

const termService = {
    getTerms: (code) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/terms-and-conditions/${code}`);
    },
    getTermsHtml: (termsFileName) => {
        return axiosLocalInstance.get(`/terms/${termsFileName}`);
    },
};

export default termService;
