import React, { useState } from 'react';
import { Grid} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export const CustomDatePicker = ({ onChange, onInvalid, helperText, errorHelperText, ...props }) => {
    const [error, setError] = useState(false);

    const handleInvalid = (event) => {
        event.preventDefault();
        if (onInvalid) {
            onInvalid(event);
        }
        setError(true);
    };

    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
        setError(false);
    };

    const refusePattern = () => {
        return /[^0-9]+/gi;
      };

    const handleBlur = (args) => {
        if (onChange) {
            const [day, month, year] = args.target.value.split('/');
            const date = new Date(+year, +month - 1, +day);
            onChange(date);
        }
        //setError(false);
    };

    return (
        

<MuiPickersUtilsProvider utils={DateFnsUtils}>
<Grid item container xs={12} direction="column" justifyContent="flex-start" alignItems="flex-start">
    <KeyboardDatePicker
    
        onInvalid={handleInvalid}
        
        openTo="year"
        views={["year", "month", "date"]}
        mask="__/__/____"

        format="dd/MM/yyyy"
        refuse={refusePattern()}
        onChange={handleChange}
        
        onBlur={handleBlur}
        error={error}
        helperText={error ? errorHelperText : helperText}
        {...props}
    />
</Grid>
</MuiPickersUtilsProvider>
            
    );
};
