import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, Container, makeStyles } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import MacroStepPage from './shared/MacroStepPage';
import Loader from '../components/loader/Loader';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import userInputService from '../services/userInput.service';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 2, 2, 2),
        textAlign: 'center',
        fontSize: '1.55rem',
    },
}));

export const PersonalAddresDataInput = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const [personalAddressData, setPersonalAddressData] = useState({});
    const [personalAddressContextData, setPersonalAddressContextData] = useState({});
    const [loading, setLoading] = useState(true);
    const [toast, setToast] = useState(null);
    const [form, setForm] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();

    const [addressNormalizationRetryCount, setAddressNormalizationRetryCount] = useState(0)
    const [addressNormalizationStatus, setAddressNormalizationStatus] = useState('');
    const [addressNormalizationStatusReason, setAddressNormalizationStatusReason] = useState('');

    const [selectedNormalizedAddress, setSelectedNormalizedAddress] = useState(null);
    const [normalizedAddresses, setNormalizedAddresses] = useState(null);

    const [normalizedAddressAlternativeIndex, setNormalizedAddressAlternativeIndex] = useState(null);


    useEffect(() => {
        async function getUserInputFields() {
            try {
                setLoading(true);
                const userInputs = (
                    await userInputService.getUserInputFields(financialProductRequest
                    )
                );
                setForm(userInputs);
            } catch (error) {
                // Doing nothing
            } finally {
                setLoading(false);
            }
        }

        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
            getUserInputFields();
        }
    }, [financialProductRequest]);

    useEffect(() => {
        async function complete() {
            const request = {
                inputMap: {
                    personalAddressData,
                    personalAddressContextData,
                    addressNormalizationRetryCount: addressNormalizationRetryCount + 1,
                    addressNormalizationStatus: addressNormalizationStatus,
                    addressNormalizationStatusReason: addressNormalizationStatusReason,
                },
            };
            setAddressNormalizationRetryCount(addressNormalizationRetryCount + 1);
            try {
                setLoading(true);
                await task.completeTask(request);
            } catch (error) {
                if (
                    error.response.data.validationErrors &&
                    error.response.data.validationErrors.length > 0 &&
                    error.response.data.validationErrors[0].propertyPath === 'addressNormalization'
                ) {
                    const errorDetail = error.response.data.validationErrors[0].errorDetail;
                    setAddressNormalizationStatus(errorDetail.status);
                    setAddressNormalizationStatusReason(errorDetail.statusReason);
                    if (errorDetail.status === 'DU') {
                        setSelectedNormalizedAddress(null);
                        setNormalizedAddresses(errorDetail.suggestedAddresses);
                    } else {
                        setToast({ message: 'No pudimos verificar este domicilio. Ingresá una dirección válida' });
                    }
                    return;
                } else {
                    const { properties, errorCode } = error.response.data;
                    const { fieldsToCompleted } = properties;
                    if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                        var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                        fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                        setToast({ message: msg });
                    }
                }
            } finally {
                setLoading(false);
            }
        }
        if (personalAddressData.customerAddressCountry) {
            complete();
        }
    }, [personalAddressData]);

    const handleNextPressed = async (event) => {
        event.preventDefault();
        //Seteo el pais Argentina

        if(normalizedAddressAlternativeIndex) {
            const address = { ...normalizedAddresses[normalizedAddressAlternativeIndex] };
            setNormalizedAddresses(null);
            selectNormalizedAddress(address);
        } else {
            setPersonalAddressData((previousState) => {
                return { ...previousState, 'customerAddressCountry': '1' };
            });
        }
        
    };

    const selectNormalizedAddress = (normalizedAddress) => {
        form.groups[1].variables.forEach((group) => {

            // SETEAR VALORES PROVENIENTES DEL RESULTADO DE NORMALIZACION.
            setPersonalAddressData((previousState) => {
                return { ...previousState, 
                    'customerAddressCountry': '1',
                    'customerStreet': normalizedAddress.streetName.substring(0, 29),
                    'customerStreetNumber': normalizedAddress.streetNumber,
                    'customerAddressProvince': normalizedAddress.provinceCode,
                    'customerAddressCity': normalizedAddress.cityCode,
                    'customerAddressCP': normalizedAddress.postalCode,
                    'customerAddress': normalizedAddress.neighborhood
                };
            });

            setPersonalAddressContextData((previousState) => {
                return { ...previousState, 
                    'customerStreet': normalizedAddress.neighborhood,
                };
            });
        });
    };

    const handleUserInputChange = (userInputs, contextData) => {
        setPersonalAddressData(userInputs);
        setPersonalAddressContextData(contextData);
    };

    const handleSelectAlternativeIndex = (index) => {
        setNormalizedAddressAlternativeIndex(index);
    }

    return (
        <MacroStepPage {...props} noBackground={true} financialProductRequest={financialProductRequest}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {form && (
                <Container maxWidth="sm">
                    <Grid container justifyContent="center">
                        <Grid item component={Box}>
                            <h1 className={classes.title}>Completá tus datos</h1>
                        </Grid>
                        <form onSubmit={handleNextPressed}>
                            <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                                <Grid item container className="mb-100-res">
                                    <CustomerDataUserInputs
                                        sameLineIndexes={[1, 2, 3]}
                                        stepName={taskDefinitionId}
                                        form={form}
                                        hashKey={financialProductRequest.id}
                                        onComplete={handleUserInputChange}
                                        normalizedAddressAlternatives={normalizedAddresses}
                                        onSelectAlternative={handleSelectAlternativeIndex}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="mb-footer startButton center mt-20-web">
                                        <Button
                                            className="width-100-per width-80-res"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}>
                                            Continuar
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Container>
            )}
        </MacroStepPage>
    );
};
