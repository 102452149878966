import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, makeStyles, Container, Modal } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import MacroStepPage from './shared/MacroStepPage';

import Loader from '../components/loader/Loader';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import userInputService from '../services/userInput.service';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 2, 2, 2),
        textAlign: 'center',
        fontSize: '1.55rem',
    },
}));

const useStylesModal = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 300,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2, 2, 3),
    },
}));

const useStylesModalText = makeStyles(() => ({
    modalText: {
        fontSize: '11px',
        color: '#000000',
        textAlign: 'justify',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
    },
}));

export const AffidavitDataInput = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [toast, setToast] = useState(null);
    const [form, setForm] = useState(null);
    const [affidavitData, setAffidavitData] = useState({});
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const classesModalText = useStylesModalText();
    const classesModal = useStylesModal();
    const [messages, setMessages] = useState([]);
    const [modalStyle] = React.useState(getModalStyle);

    useEffect(() => {
        async function getUserInputFields() {
            try {
                setLoading(true);
                const userInputs = (
                    userInputService.getDDJJUserInputFields());
                setForm(userInputs);
            } catch (error) {
                // Doing nothing
            } finally {
                setLoading(false);
            }
        }

        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
            getUserInputFields();
        }
    }, [financialProductRequest]);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const handleNextPressed = (event) => {
        event.preventDefault();
        if (!validateAffidavit()) {
            setOpenModal(true);
            return;
        }
        nextStep();
    };

    const handleUserInputChange = (userInputs) => {
        setAffidavitData(userInputs);
    };

    const validateAffidavit = () => {
        let msg = [];
        if (affidavitData.customerPEP === 'true') {
            msg.push('Alcanzado dentro de la nómina de Personas Expuestas Políticamente');
        }
        if (affidavitData.customerOCDE === 'true') {
            msg.push('OCDE (CRS)');
        }

        if (affidavitData.customerSujetoObligadoUIF === 'true') {
            msg.push('Sujeto Obligado ante la UIF');
        }

        if (affidavitData.customerUSCitizenFATCA === 'true') {
            msg.push('US Person - FATCA');
        }

        setMessages(msg);
        return msg.length === 0;
    };

    const handleContinue = () => {
        setOpenModal(false);
        nextStep();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const nextStep = async () => {
        try {
            setLoading(true);
            const request = {
                inputMap: { affidavitData },
            };
            await task.completeTask(request);
        } catch (error) {
            var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                }
            }
            setToast({ message: msg });
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const ModalBody = (
        <div style={modalStyle} className={classesModal.paper}>
            <p className="fs-15 color-dark-gray fw-500">Seleccionaste que estás alcanzado por las regulaciones</p>
            <ul>
                {messages.map((message, index) => (
                    <li key={index} className={classesModalText.modaltext} id="simple-modal-description">
                        {message}
                    </li>
                ))}
            </ul>
            <p className="fs-15 color-dark-gray fw-500 mt-2">¿Confirmás tu selección?</p>

            <div className="mt-2" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <Button onClick={handleCloseModal} className="" color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleContinue} className="" variant="contained" color="primary">
                    Confirmar
                </Button>
            </div>
        </div>
    );

    return (
        <MacroStepPage {...props} noBackground={true} financialProductRequest={financialProductRequest}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {form && (
                <Container maxWidth="sm">
                    <Grid container justifyContent="center">
                        <Grid item component={Box}>
                            <h1 className={classes.title}>Completá tus datos</h1>
                        </Grid>
                        <form onSubmit={handleNextPressed}>
                            <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                                <Grid item container className="mb-100-res">
                                    <CustomerDataUserInputs
                                        stepName={taskDefinitionId}
                                        form={form}
                                        hashKey={financialProductRequest.id}
                                        onComplete={handleUserInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="mb-footer startButton center mt-20-web">
                                        <Button
                                            className="width-100-per width-80-res"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}>
                                            Continuar
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description">
                                {ModalBody}
                            </Modal>
                        </form>
                    </Grid>
                </Container>
            )}
        </MacroStepPage>
    );
};
