import React, { useState } from 'react';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';

export const CustomSelect = ({ children, required, fullWidth, label, onChange, onInvalid, helperText, errorHelperText, ...props }) => {
    const [error, setError] = useState(false);
    const { name } = props;

    const handleInvalid = (event) => {
        event.preventDefault();
        if (onInvalid) {
            onInvalid(event);
        }
        setError(true);
    };

    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
        setError(false);
    };

    return (
        <FormControl fullWidth={fullWidth} required={required} error={error} variant="outlined">
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
                labelId={`${name}-label`}
                id={name}
                label={label}
                variant="outlined"
                onInvalid={handleInvalid}
                onChange={handleChange}
                error={error}
                color="secondary"
                {...props}>
                {children}
            </Select>
            <FormHelperText>{error ? errorHelperText : helperText}</FormHelperText>
        </FormControl>
    );
};
