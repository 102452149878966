import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as qs from 'qs';
import { Container, Grid } from '@material-ui/core';
import LandingTemplate from './shared/LandingTemplate';
import LoginForm from '../components/login/LoginForm';
import Loader from '../components/loader/Loader';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import { bpmService } from '../services/bpm.service';
import { businessOriginationService } from '../services/businessOrigination.service';
import { authenticationService } from '../services/authentication.service';
import useCurrentTask from '../components/bpm/useCurrentTask';
import '../styles/pages/login.scss';
import { ONBOARDING_BASE_URL, getEnv } from '../env';
import bnplService from '../services/bnpl.service';

export const LoginPage = (props) => {
    const [bnplOperation, setBnplOperation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bnplRequestId, setBnplRequestId] = useState(null);
    const [toast, setToast] = useState(null);
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const [businessKey, setBusinessKey] = useState(null);
    const history = useHistory();
    const { t } = useTranslation();
    useCurrentTask(processInstanceId, businessKey, '');

    const [marketingData, setMarketingData] = useState({});

    useEffect(() => {
        async function getBnplOperation(bnplId) {
            try {
                setLoading(true);

                let result = (await bnplService.getById(bnplId)).data;
                setBnplOperation(result);
                setLoading(false);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
                setLoading(false);
            }
        }

        if (props.location.search) {
            const { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            setBnplRequestId(id);

            getBnplOperation(id)

            let queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            const filteredMarketingData = Object.keys(queryParams)
                .filter((key) => key.indexOf('utm') > -1)
                .reduce((obj, key) => {
                    obj[key] = queryParams[key];
                    return obj;
                }, {});
            setMarketingData(filteredMarketingData);
            console.log(filteredMarketingData);
        }
    }, []);

    const createProcessInstance = async (customerInfo, recaptchaTkn) => {
        try {
            setLoading(true);
            const var_url = getEnv(ONBOARDING_BASE_URL);
            const url_ = var_url === "" ?  window.location.origin : var_url;
            const filter = {
                url: url_
            };
            const businessOrigination = (await businessOriginationService.search(filter)).data.result[0];
            if (businessOrigination) {
                const authPayload = {
                    cellphone: customerInfo.cellphone,
                    pin: customerInfo.cellphonePin,
                    recaptchaToken: recaptchaTkn,
                };
                const authResult = await authenticationService.authenticate(authPayload);
                localStorage.setItem('access_token', authResult.data.access_token);
                localStorage.setItem('refresh_token', authResult.data.refresh_token);
                
                const payload = {
                    startWorkflowId: businessOrigination.businessSchema.bpmProcessName,
                    variables: {
                        businessOriginationId: businessOrigination.id,
                        businessSchemaId: businessOrigination.businessSchema.id,
                        originatorId: businessOrigination.originator.id,
                        customerId: customerInfo.idNumber,
                        customerTaxId: customerInfo.taxId,
                        customerFullName: customerInfo.customerFullName,
                        customerFirstName: customerInfo.customerFirstName,
                        customerLastName: customerInfo.customerLastName,
                        customerBirthDate: customerInfo.birthday ? (customerInfo.birthday.getFullYear() + "-" + (customerInfo.birthday.getMonth() < 9 ? "0" : "") + (customerInfo.birthday.getMonth()+1) + "-" + (customerInfo.birthday.getDate() < 10 ? "0" : "") + customerInfo.birthday.getDate()) : undefined,
                        customerEmail: customerInfo.email,
                        customerCellphone: customerInfo.cellphone,
                        customerCellphonePin: customerInfo.cellphonePin,
                        ecommerceIntegrationRequestId: bnplRequestId,
                        termsAndConditionsId: customerInfo.termsAndConditionsId,
                        marketingData: marketingData,
                    },
                };
                const { businessKey, processInstanceId } = (await bpmService.createWorkflowBusinessInstance(payload)).data;
                setBusinessKey(businessKey.substring(4));
                setProcessInstanceId(processInstanceId);
            } else {
                setToast({ message: t(`WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION`) });
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                const { cause, code } = error.response.data;
                if (error.response.data.code === 409) {
                    switch (error.response.data.errorCode) {
                        case 'BNPL_OPERATION_STATUS_MUST_BE_CREATED':
                            history.push(`/noRetryable/GENERAL/${bnplRequestId}`);
                            break;
                        case 'A_FINANCIAL_PRODUCT_REQUEST_RECENTLY_FINALIZED_EXISTS':
                            history.push(`/noRetryable/RECENTLY_ACTIVE_PRODUCT/${bnplRequestId}`);
                            break;
                        case 'ACTIVE_BNPL_OPERATION_FOR_SAME_ORDER_EXISTS':
                            history.push(`/noRetryable/ACTIVE_BNPL_OPERATION/${bnplRequestId}`);
                            break;
                        case 'A_FINANCIAL_PRODUCT_REQUEST_IN_ACTIVATION_PROCESS_EXISTS':
                            history.push(`/noRetryable/ACTIVE_PRODUCT/${bnplRequestId}`);
                            break;
                        default:
                            setToast({ message: t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`) });
                            break;
                    }
                } else if (error.response.data.code === 401 && error.response.data.errorCode === 'UNAUTHORIZED') {    
                    setToast({ message: t(`WORKFLOW_ERROR.${error.response.data.cause}.DESCRIPTION`) });
                } else {
                    setToast({ message: t(`WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION`) });
                }
            }
            localStorage.clear();
        } finally {
            setLoading(false);
        }
    };

    return (
        <LandingTemplate {...props}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {bnplRequestId && bnplOperation && (
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={10} container>
                            <LoginForm customerId={bnplOperation.providerOrderData?.customerId} onSubmit={createProcessInstance} disabled={loading}></LoginForm>
                        </Grid>
                        <Grid item xs={false} sm={1}></Grid>
                    </Grid>
                </Container>
            )}
        </LandingTemplate>
    );
};
