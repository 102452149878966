import React from 'react';
import '../../styles/components/offerTerms.scss';
import { numberToPercentageText } from '../../components/utils/numberUtils';
import { Grid } from '@material-ui/core';

export const OfferTerms = (props) => {
    const { offer } = props;

    if (offer) {
        return (
            <Grid container justifyContent="space-evenly">
                <Grid>
                    <p className="fs-13 fw-bold color-dark-gray mt-4">TNA: {numberToPercentageText(offer.nominalAnnualRate, 2)} </p>
                </Grid>
                <Grid>
                    <p className="fs-13 fw-bold color-dark-gray mt-4">TEA: {numberToPercentageText(offer.effectiveAnnualRate, 2)}</p>
                </Grid>
                <Grid>
                    <p className="fs-13 fw-bold color-dark-gray mt-4">CFTEA: {numberToPercentageText(offer.totalFinancialCost, 2)}</p>
                </Grid>
            </Grid>
        );
    }
    return null;
};
