import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme } from '@material-ui/core/styles';

const dropdownIconNode = <ExpandMoreIcon style={{ color: '#4a4a4a' }} />;

const dropdownIconComponent = () => {
    return dropdownIconNode;
};
const font = "'Titillium Web'";

const Theme = createTheme({
    palette: {
        primary: {
            main: '#FF4452',
        },
        secondary: {
            main: '#203AE9',
        },
        terms: {
            main: '#00000',
        },
        action: {
            disabledBackground: '#9b9b9b',
            disabled: '#ffffff',
        },
    },
    button: {
        height: '300px',
    },
    props: {
        MuiSelect: {
            IconComponent: dropdownIconComponent,
        },
        MuiAutocomplete: {
            popupIcon: dropdownIconNode,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: '30px',
                fontWeight: 'bolder',
                textTransform: 'initial',
                fontSize: '1.1em',
            },
            outlinedPrimary: {
                border: '2px solid #FF4452',
            },
        },

        MuiInput: {
            underline: {
                '&::after': {
                    borderBottomColor: '#203AE9',
                },
            },
        },
    },
    typography: {
        fontFamily: ['Public sans', 'Arial', 'sans-serif'].join(','),
        body1: {
            fontSize: '0.9rem',
        },
        h1: {
            fontFamily: ['Titillium Web', 'sans-serif'].join(','),
        },
        h2: {
            fontFamily: ['Titillium Web', 'sans-serif'].join(','),
        },
        h3: {
            fontFamily: ['Titillium Web', 'sans-serif'].join(','),
        },
        h4: {
            fontFamily: ['"Titillium Web Bold"', 'sans-serif'].join(','),
        },
        h5: {
            fontFamily: font,
            fontWeight: '700',
        },
        h6: {
            fontFamily: font,
            fontWeight: '400',
        },
    },
});

export default Theme;
