import React, { useEffect, useRef } from 'react';
import { TOC_HTTP, TOC_PRD, getEnv } from '../../env';

const TocAutocaptureSelfie = (props) => {
    const { session, onSuccess, onFailure, className } = props;
    const refContainer = useRef(null);

    useEffect(() => {
        const options = {
            locale: 'es',
            session_id: session.session_id,
            callback: onSuccess,
            failure: onFailure,
            http: `${getEnv(TOC_HTTP)}`,
        };

        if (getEnv(TOC_PRD) === 'true') {
            options.alt_server = 'https://prod-liveness.tocws.com';
            options.url_lbliv = 'https://prod-api.7oc.cl/liveness/image-saver/v1';
        }

        window.TOCliveness(refContainer.current, options);

        return function cleanup() {
            var node = document.getElementById('tocLivenessDivID');
            while (node.hasChildNodes()) {
                node.removeChild(node.firstChild);
            }
        };
    }, [refContainer]);

    useEffect(() => {
        
        return function cleanup() {
            var node = document.getElementById('tocLivenessDivID');
            while (node.hasChildNodes()) {
                node.removeChild(node.firstChild);
            }
        };
    }, []);

    return <div id="tocLivenessDivID" className={className} ref={refContainer} />;
};

export default TocAutocaptureSelfie;