import React, { useState, useMemo, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import { ManualDocumentUploader } from './shared/ManualDocumentUploader';
import hintLight from '../assets/hint-light.svg';
import hintBlur from '../assets/hint-blur.svg';
import hintWarning from '../assets/hint-warning.svg';
import Loader from '../components/loader/Loader';
import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import MacroStepPage from './shared/MacroStepPage';
import eventTrackingService from '../services/eventtracking.service';

export const FrontDocumentSelection = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId, title } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const [documentResponseId, setDocumentResponseId] = useState(null);
    const [activateNext, setActivateNext] = useState(false);
    const [loading, setLoading] = useState(false);
    const analyticsStep = 6;

    useEffect(() => {
        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
        }
    }, [financialProductRequest]);

    const onDocumentUploaded = (imgSrcUploaded, uploadMethod, documentResponse) => {
        eventTrackingService.trackEvent('dni frente', uploadMethod, null, analyticsStep);
        setDocumentResponseId(documentResponse.documentId);
        setActivateNext(imgSrcUploaded ? true : false);
    };

    const handleNextPressed = (event) => {
        event.preventDefault();

        nextStep();
    };

    const nextStep = async () => {
        try {
            setLoading(true);
            const request = {
                inputMap: { id_front: documentResponseId },
            };
            await task.completeTask(request);
        } catch (error) {
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                    setMessage(msg);
                    setOpen(true);
                }
            } else {
                console.error(error);
            }
        } finally {
            setLoading(false);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const hintDni = useMemo(() => {
        return (
            <div className="center">
                <div>
                    <div align="center">
                        <img src={hintLight} alt="" style={{ marginRight: '12px' }} />
                        Buscá un lugar bien iluminado
                    </div>

                    <div align="center">
                        <img src={hintBlur} alt="" style={{ marginRight: '16px' }} />
                        Que los datos se vean claros
                    </div>

                    <div align="center">
                        <img src={hintWarning} alt="" style={{ marginRight: '16px' }} />
                        Utilizá tu último DNI vigente
                    </div>
                </div>
            </div>
        );
    }, []);

    return (
        <div>
            <Loader loading={loading} />
            {isMobile ? (
                <MacroStepPage {...props} noBackground financialProductRequest={financialProductRequest}>
                    <form onSubmit={handleNextPressed}>
                        <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                            {task && task.task && (
                                <Grid item container xs={12} direction="row" justifyContent="center" className="mb-100-res">
                                    <ManualDocumentUploader
                                        title={title}
                                        processInstanceId={processInstanceId}
                                        taskId={task.task.taskId}
                                        hintInfo={hintDni}
                                        businessKey={businessKey}
                                        document_={'id_front'}
                                        onDocumentUploaded={onDocumentUploaded}
                                        onHandleNext={handleNextPressed}
                                        selfie={false}
                                    />
                                </Grid>
                            )}
                            <br />
                            <Grid item xs={12} sm={5} md={6} lg={4} style={{ zIndex: 100 }}>
                                <div className="mb-footer startButton center mb-20-web mt-20-web">
                                    <Button
                                        disabled={!activateNext}
                                        className="width-100-per width-80-res"
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Continuar
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                    </form>
                </MacroStepPage>
            ) : (
                <ContinueMobile processInstanceId={processInstanceId} businessKey={businessKey} />
            )}
        </div>
    );
};
