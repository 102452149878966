import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';

export const macroRiskAnalysisFolderService = {
    evaluateMacroPublicRiskAnalysisFolder: (requestDto) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/macro/public-risk-analysis-folders`, requestDto);
    },
};

export default macroRiskAnalysisFolderService;
