import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import bpmService from '../../services/bpm.service';
import financialProductsRequestsService from '../../services/financialProductsRequestsService';
import processInstanceService from '../../services/processInstance.service';
import eventTrackingService from '../../services/eventtracking.service';

import redirectUtils from './useRedirect.jsx'; 

const useCurrentTask = (processInstanceId, businessKey, taskDefinitionId) => {
    const [task, setTask] = useState(null);
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [retryPending, setRetryPending] = useState(false);
    const [error, setError] = useState(null);
    const history = useHistory();

    const redirect = redirectUtils();

    useEffect(() => {
        let currentTask = null;
        async function getCurrentTask(processInstanceId, businessKey) {
            try {
                const pendingTasksResponse = await bpmService.searchWorkflowBusinessInstancePendingTasks(processInstanceId);
                currentTask = pendingTasksResponse.data.result[0];
                
                if (currentTask) {
                    if (currentTask.taskDefinitionId !== taskDefinitionId) {
                        history.push(`/${currentTask.taskDefinitionId}/${businessKey}${history.location.search}`);
                    } else {
                        setTask(currentTask);
                    }
                } else {
                    const fpRequest = (await financialProductsRequestsService.getFinancialProductsRequest(businessKey)).data;
                    redirect.redirectFPRByStatus(businessKey, fpRequest.status);
                }
            } catch (error) {
                if (error.response) {
                    const { cause, code } = error.response.data;
                    if (error.response.data.code === 404) {
                        const fpRequest = (await financialProductsRequestsService.getFinancialProductsRequest(businessKey)).data;
                        redirect.redirectFPRByStatus(businessKey, fpRequest.status);
                    } else {
                        alert(cause || 'Error durante la ejecución.');
                    }
                }
            }
        }

        if (processInstanceId && businessKey) {
            getCurrentTask(processInstanceId, businessKey);
        }
    }, [processInstanceId, businessKey, taskCompleted, retryPending]);

    const getAnalyticsStepNumber = (taskName) => {
        var opts = {
            documentsType: 5,
            documentsUploadFront: 6,
            documentsUploadBack: 7,
            documentsUploadSelfie: 8,
            offerSelection: 9,
            customerDataInputDirect: 10,
            disclosure: 11,
        };

        return opts[taskName] ? opts[taskName] : 0;
    };

    const completeTask = async (parameters) => {
        try {
            await bpmService.resolveWorkflowBusinessInstanceTask(task.processInstanceId, task.taskId, parameters);
            const actualTaskName = `${task.taskDefinitionId}_completed`;
            // track event to analytics
            eventTrackingService.trackEvent(actualTaskName, null, null, getAnalyticsStepNumber(task.taskDefinitionId));
            setTaskCompleted(true);
        } catch (error) {
            if (error.response) {
                if (error.response.data.code === 404) {
                    setRetryPending(true);
                } else {
                    throw error;
                }
            }
            throw error;
        }
    };

    const goToPreviousTask = async () => {
        if (
            !Array.isArray(task.availableEventNames) &&
            !task.availableEventNames.length &&
            (!Array.isArray(parentAvailableEventNames) || !task.parentAvailableEventNames.length)
        ) {
            return;
        }
        const triggerConfig = {
            processInstanceId:
                task.availableEventNames && task.availableEventNames.length > 0 ? task.processInstanceId : task.parentProcessInstanceId,
            eventName:
                task.availableEventNames && task.availableEventNames.length > 0 ? task.availableEventNames[0] : task.parentAvailableEventNames[0],
        };
        try {
            const nextTask = (await processInstanceService.triggerTaskEvent(hashKey, triggerConfig)).data;

            history.push(`/${nextTask.taskDefinitionId}/${hashKey}${history.location.search}`);
        } catch (error) {
            // TODO Ver manejo de este error. Hacer throw con el mensaje que actualmente no se está viendo
            if (error.response && error.response.data) {
                const { errorCode } = error.response.data;
                if (errorCode === 'EXPIRED') {
                    alert('La Solicitud ha vencido. Debe iniciar una nueva.');
                    history.push(`/${history.location.search}`);
                }
            }
            throw error;
        }
    };

    const goToTask = async (eventName) => {};

    const hasEvents = () => {
        return (
            task !== null &&
            ((Array.isArray(task.availableEventNames) && task.availableEventNames.length) ||
                (Array.isArray(task.parentAvailableEventNames) && task.parentAvailableEventNames.length))
        );
    };

    return {
        task: task,
        completeTask: completeTask,
        goToPreviousTask: goToPreviousTask,
        goToTask: goToTask,
        hasEvents: hasEvents,
        error: error,
    };
};

export default useCurrentTask;
