import React, { useState } from 'react';
import { Button, Grid, Box, makeStyles, Container, Paper } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import useCurrentTask from '../components/bpm/useCurrentTask';
import bnplService from '../services/bnpl.service';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import MacroStepPage from './shared/MacroStepPage';
import Loader from '../components/loader/Loader';
import { numberToCurrencyText, numberToPercentageText } from '../components/utils/numberUtils';
import { useEffect } from 'react';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import financialProductsRequestsService from '../services/financialProductsRequestsService';

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#fff',
        margin: theme.spacing(0, 2, 2, 2),
        textAlign: 'center',
        fontSize: '1.55rem',
    },
    orderSummary: {
        margin: theme.spacing(4, 3, 2, 3),
        textAlign: 'center',
        fontSize: '1.3rem',
        lineHeight: '1.8rem',
    },
    disclosureAcceptedText: {
        textAlign: 'center',
        fontSize: '1rem',
    },
    continueButton: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    orderId: {
        margin: theme.spacing(0, 0, 5, 0),
        textAlign: 'center',
    },
    orderLineItem: {
        margin: theme.spacing(0, 0, 0, 0),
    },
    paper: {
        margin: theme.spacing(1, 1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '5%',
    },
    loanDetails: {
        margin: theme.spacing(0, 2, 0, 2),
        color: '#515151',
        fontSize: '1rem',
        lineHeight: '1.9rem',
        borderBottom: '1px solid #ddd',
    },
    loanDetailsBottom: {
        margin: theme.spacing(0, 2, 0, 2),
        color: '#515151',
        fontSize: '1rem',
        lineHeight: '1.9rem',
    },
    loanDetailsContainer: {
        margin: theme.spacing(4, 0, 4, 0),
    },
    orderNotice: {
        margin: theme.spacing(1, 2, 3, 2),
        color: '#515151',
        fontSize: '0.9rem',
        lineHeight: '1.4rem',
        textAlign: 'center',
    },
}));

export const DisclosurePage = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const { t } = useTranslation();
    const [disclosureAccepted, setDisclosureAccepted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(null);
    const classes = useStyles();
    const [bnplOperationId, setBnplOperationId] = useState(null);
    const [bnplOperation, setBnplOperation] = useState(null);

    useEffect(() => {
        if (financialProductRequest) {
            setBnplOperationId(financialProductRequest.requestContextData.ecommerceIntegrationRequestId);
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
        }
    }, [financialProductRequest]);

    useEffect(() => {
        if (bnplOperationId) {
            getBnplOperation(bnplOperationId);
        }
    }, [bnplOperationId]);

    const getBnplOperation = async () => {
        try {
            setLoading(true);
            const response = await bnplService.getById(bnplOperationId);
            setBnplOperation(response.data);
        } catch (error) {
            setToast({ message: t(`WORKFLOW_ERROR.GENERAL.DESCRIPTION`) });
        } finally {
            setLoading(false);
        }
    };

    const handleDisclosureAcceptedChange = (event) => {
        setDisclosureAccepted(event.target.checked);
    };

    const handleNextClicked = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const request = {
                inputMap: {},
            };
            await task.completeTask(request);
        } catch (error) {
            if (error.response && error.response.data) {
                const { errorCode } = error.response.data;
                setToast({ message: t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) });
            } else {
                setToast({ message: 'Algo salió mal al intentar avanzar con tu solicitud. Reintentá en unos segundos' });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDisclosureDownloadClicked = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const documentResponse = await financialProductsRequestsService.getDocument(businessKey, financialProductRequest.documentData.contract.documentProviderId);
            const fileResponse = await financialProductsRequestsService.getDocumentFile(
                businessKey,
                documentResponse.data.id,
                documentResponse.data.files[0].id
            );
            window.open(window.URL.createObjectURL(fileResponse.data));
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorCode) {
                const { errorCode } = error.response.data;
                setToast({ message: t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) });
            } else {
                setToast({ message: 'Algo salió mal al descargar el contrato. Reintentá en unos segundos' });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <MacroStepPage {...props} financialProductRequest={financialProductRequest}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {bnplOperation && (
                <Container maxWidth="sm">
                    <Grid item xs={12} container justifyContent="center">
                        <Grid item component={Box}>
                            <h1 className={classes.title} marginTop={2}>
                                Revisá y aceptá el contrato
                            </h1>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.paper}>
                                <Grid item container justifyContent="center">
                                    <Grid item>
                                        <p className={classes.orderSummary}>
                                            {t(['disclosurePage.title.' + bnplOperation.providerId, 'disclosurePage.title.default']) + bnplOperation.merchantOrderID}{' '}
                                            <b>por {numberToCurrencyText(financialProductRequest.selectedFinancialProducts[0].capitalAmount, 2)}</b>{' '}
                                            en{' '}
                                            <b>
                                                {financialProductRequest.selectedFinancialProducts[0].numberOfInstallments} cuotas de{' '}
                                                {numberToCurrencyText(financialProductRequest.selectedFinancialProducts[0].avgInstallmentAmount, 2)}
                                            </b>
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <Grid item>
                                        <Checkbox
                                            checked={disclosureAccepted}
                                            onChange={handleDisclosureAcceptedChange}
                                            name="accepted"
                                            color="primary"
                                        />
                                        <span className={classes.disclosureAcceptedText}>
                                            Leí y acepto{' '}
                                            <a href="#" onClick={handleDisclosureDownloadClicked}>
                                                el contrato
                                            </a>
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} className={classes.loanDetailsContainer}>
                                    <Grid item container justifyContent="space-between" className={classes.loanDetails}>
                                        <p>Sistema de amortización</p>
                                        <p>Francés</p>
                                    </Grid>
                                    <Grid item container justifyContent="space-between" className={classes.loanDetails}>
                                        <p>Tipo de tasa</p>
                                        <p>Fija</p>
                                    </Grid>
                                    <Grid item container justifyContent="space-between" className={classes.loanDetailsBottom}>
                                        <Grid>
                                            <p className="color-dark-gray fs-14">
                                                TNA: {numberToPercentageText(financialProductRequest.selectedFinancialProducts[0].tna, 2)}{' '}
                                            </p>
                                        </Grid>
                                        <Grid>
                                            <p className="color-dark-gray fs-14">
                                                TEA: {numberToPercentageText(financialProductRequest.selectedFinancialProducts[0].tea, 2)}
                                            </p>
                                        </Grid>
                                        <Grid>
                                            <p className="fs-17 fw-bold color-dark-gray fs-14">
                                                CFTEA: {numberToPercentageText(financialProductRequest.selectedFinancialProducts[0].cft, 2)}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <Grid item>
                                        <p className={classes.orderNotice}>{t(['disclosurePage.footer.' + bnplOperation.providerId, 'disclosurePage.footer.default'])}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid container justifyContent="center">
                            <Button
                                variant="contained"
                                className={classes.continueButton}
                                disableElevation={true}
                                fullWidth={true}
                                color="primary"
                                onClick={handleNextClicked}
                                disabled={loading || !disclosureAccepted}>
                                Aceptar el contrato
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </MacroStepPage>
    );
};
