import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const BUSINESS_ORIGINATION_PATH = '/business-originations';

export const businessOriginationService = {
    
    search: (filter) => {
        return axiosInstance.get(`${RESTAPI_VERSION}${BUSINESS_ORIGINATION_PATH}`, { params: filter });
    },
    
};

export default businessOriginationService;