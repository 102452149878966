import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';

export const authenticationService = {
    authenticate: function (payload) {
        return axiosInstance.post(`${RESTAPI_VERSION}/macro/authentication/login`, payload);
    },

    authenticateWithIp: function (payload) {
        return axiosInstance.post(`${RESTAPI_VERSION}/macro/authentication/login-with-ip`, payload);
    },

    refresh: function (payload) {
        return axiosInstance.post(`${RESTAPI_VERSION}/macro/authentication/refresh-token`, payload);
    },
};

export default authenticationService;
