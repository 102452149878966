import React, { useState, useEffect } from 'react';
import LandingTemplate from './shared/LandingTemplate';
import { useHistory } from 'react-router-dom';
import { Grid, Container, Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../components/loader/Loader';
import Toaster from '../components/alerts/Toaster';
import bnplService from '../services/bnpl.service';
import { useTranslation } from 'react-i18next';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import * as qs from 'qs';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        justifyContent: 'space-between',
        height: '80px',
    },
    toolbarLogo: {
        height: '40px',
    },
    title: {
        margin: theme.spacing(5, 0, 0, 0),
        textAlign: 'center',
    },
    orderId: {
        margin: theme.spacing(0, 0, 5, 0),
        textAlign: 'center',
        color: `${theme.palette.secondary.main}`,
    },
    orderLineItem: {
        margin: theme.spacing(0, 0, 0, 0),
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    total: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    divider: {
        margin: theme.spacing(3, 0, 3, 0),
    },
    info: {
        margin: theme.spacing(5, 0, 5, 0),
        textAlign: 'center',
        color: `${theme.palette.primary}`,
    },
}));

export const LandingPage = (props) => {
    const [commerceOperationData, setCommerceOperationData] = useState(null);
    const [loading, setLoading] = useState(null);
    const history = useHistory();
    const [toast, setToast] = useState(null);
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        async function getCommerceOperationData(bnplId) {
            try {
                setLoading(true);

                let result = (await bnplService.getById(bnplId)).data;
                console.log(result)
                setCommerceOperationData(result);
                setLoading(false);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
                setLoading(false);
            }
        }

        if (props.location.search) {
            const { id: bnplId } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            if (bnplId) {
                getCommerceOperationData(bnplId);
            }
        }
        localStorage.clear();
    }, []);

    const handleNextClicked = (event) => {
        event.preventDefault();
        history.push('/customer-identification' + window.location.search);
    };

    return (
        <LandingTemplate showBanner={true} {...props}>
            <Toaster toast={toast} />
            <Loader loading={loading} />
            {commerceOperationData && (
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={10} container>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <h1 className={classes.title}>{t(['landingPage.title.' + commerceOperationData.providerId, 'landingPage.title.default'])}</h1>
                                    <h1 className={classes.orderId}>{commerceOperationData.merchantOrderID}</h1>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {commerceOperationData.cartInfo &&
                                    commerceOperationData.cartInfo.map((cartItem, i) => (
                                        <Typography key={i} className={classes.orderLineItem}>
                                            {cartItem.quantity} {cartItem.description}
                                        </Typography>
                                    ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid container justifyContent="space-between" className={classes.total}>
                                <Grid item>
                                    <Typography variant="h6">Total</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        <strong>{numberToCurrencyText(commerceOperationData.cartAmount, 2)}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="body2" className={classes.info}>
                                <strong>
                                    {t(['landingPage.footer.' + commerceOperationData.providerId, 'landingPage.footer.default'])}
                                </strong>
                            </Typography>
                            <Grid container justifyContent="center">
                                <Button variant="contained" fullWidth={true} color="primary" disableElevation={true} onClick={handleNextClicked}>
                                    Comenzar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </LandingTemplate>
    );
};
