export const ONSCORE_LOCAL_BASE_URL = 'ONSCORE_LOCAL_BASE_URL';
export const ENCRYPT_MESSAGES = 'ENCRYPT_MESSAGES';
export const ENCRYPT_KEY = 'ENCRYPT_KEY';
export const GOOGLE_RECAPTCHA_KEY = 'GOOGLE_RECAPTCHA_KEY';
export const GTM_CODE = 'GTM_CODE';
export const ONSCORE_BASE_URL = 'ONSCORE_BASE_URL';
export const ONSCORE_JWT = 'ONSCORE_JWT';
export const TOC_HTTP = 'TOC_HTTP';
export const TOC_PRD = 'TOC_PRD';
export const CATALOG_COUNTRY_ID = 'CATALOG_COUNTRY_ID';
export const ONBOARDING_BASE_URL = 'ONBOARDING_BASE_URL';
export const ONSCORE_LOCAL_JWT = 'ONSCORE_LOCAL_JWT';
export const GOOGLE_MAP_API_KEY = 'GOOGLE_MAP_API_KEY';
export const TOC_AUTOCAPTURE_LIB = 'TOC_AUTOCAPTURE_LIB';
export const TOC_LIVENESS_LIB = 'TOC_LIVENESS_LIB';
export const BUSINESS_ORIGINATION_URL = 'BUSINESS_ORIGINATION_URL';

export const getEnv = (key) => window.env[key];