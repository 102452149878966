import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const BPM_PROCESS_NAME = 'process-instances';
const BUSINESS_PROCESS = 'startBNPLWorkflowProcess';

export const processInstanceService = {

    createProcessInstance: (variables) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/${BPM_PROCESS_NAME}`, {
            processDefinitionKey: BUSINESS_PROCESS,
            parameters: variables
        });
    },

    searchTasks: (businessKey) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/${BPM_PROCESS_NAME}/${businessKey}/tasks`);
    },

    completeTask: (businessKey, task, requestDto) => {
        return axiosInstance.put(`${RESTAPI_VERSION}/${BPM_PROCESS_NAME}/${businessKey}/tasks/${task.taskId}`, requestDto);
    },

    uploadDocument: (businessKey, taskId, document, file) => {
        const form_Data = new FormData();
        form_Data.append('file', file, document + '.jpg');
        form_Data.append('taskId', taskId);
        form_Data.append('documentName', document);
        form_Data.append('documentType', document);
        form_Data.append('financialProductsRequestId', businessKey);
        return axiosInstance.post(`${RESTAPI_VERSION}/process-instances/financial-products-request-files`, form_Data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    triggerTaskEvent: (businessKey, taskId, triggerConfig) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/process-instances/${businessKey}/tasks/${taskId}/event-triggers`, triggerConfig);
    },
};

export default processInstanceService;