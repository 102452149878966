import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Link, Grid, Button, IconButton, Box } from '@material-ui/core';
import bpmService from '../../services/bpm.service';
import tocService from '../../services/toc.service';
import '../../styles/components/stepperValidation.scss';
import { useTranslation } from 'react-i18next';
import '../../styles/components/toc.scss';
import Loader from '../../components/loader/Loader';
import Toaster from '../../components/alerts/Toaster';
import { BiCloudUpload } from 'react-icons/bi';
import TocAutocaptureSelfie from '../../components/toc/TocAutocaptureSelfie'; 
import selfieImg from '../../assets/selfie_new.svg';
import eventTrackingService from '../../services/eventtracking.service';
import { TOC_AUTOCAPTURE_LIB, TOC_LIVENESS_LIB, getEnv } from '../../env';

const useStyles = makeStyles((theme) => ({
    captureStylePortrait: {
        width: '80%',
        height: 'auto',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            maxHeight: '250px',
            maxWidth: '100%',
        },
    },

    borderBox: {
        borderStyle: 'dashed',
        borderWidth: '1px',
    },
}));

export const ManualDocumentUploader = (props) => {
    const { title, hintInfo, processInstanceId, taskId, document_, businessKey, onDocumentUploaded, onHandleNext, selfie } = props;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Se produjo un error');
    const [imgSrc, setImgSrc] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(null);
    const [showTOC, setShowTOC] = useState(false);
    const [showLiveness, setShowLiveness] = useState(false);
    const [token, setToken] = useState(null);
    const timeoutHandler = useRef();

    const getToken = async () => {
        try {
            const newToken = (await tocService.getUploadToken(businessKey)).data;
            
            setToken(newToken);
            setImgSrc(null);
            onDocumentUploaded(null);

        } catch (error) {
            setMessage(error.response.data);
            setOpen(true);
        }
    };
    
    useEffect(() => {
        
        if (document_ && !token && selfie && !imgSrc) {
            setLoading(true);
            loadScripts();
            getToken();
        }
    }, [document_]);

    const uploadFile = async (file, b64CaptureFile, uploadMethod) => {
        const token = {
            session_Id: 'N/A',
        };
        setLoading(true);
        const payload = {
            file: file,
            document: document_,
            taskId: taskId,
            processInstanceId: processInstanceId,
            financialProductsRequestId: businessKey,
        };
        await bpmService
            .createFinancialProductsRequestFile(payload)
            .then((response) => {
                setImgSrc(b64CaptureFile);                
                onDocumentUploaded(document_, uploadMethod, response.data);
                setLoading(false);
            })
            .catch((error) => {
                setImgSrc(null);
                onDocumentUploaded(null);
                clearTimeout(timeoutHandler.current);
                setLoading(false);
                if (error.data) {
                    if (error.response.data.errorCode) {
                        if (error.response.data.errorCode === 'DOCUMENT_NUMBER_MISMATCH') {
                            // Vamos a obviar la validacion si el documento no coincide.
                            onDocumentUploaded(document_);
                        } else {
                            setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.data.errorCode}.DESCRIPTION`));
                            setOpen(true);
                        }
                    } else {
                        setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.status}.DESCRIPTION`));
                        setOpen(true);
                    }
                } else {
                    if (error.response.data.errorCode === 'DOCUMENT_CANNOT_READ_BARCODE') {
                        setMessage(t(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_CANNOT_READ_BARCODE.DESCRIPTION`));
                        setOpen(true);
                    } else {
                        if (error.response.data.errorCode === 'DOCUMENT_INCORRECT_BARCODE') {
                            setMessage(t(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_INCORRECT_BARCODE.DESCRIPTION`));
                            setOpen(true);
                        } else {
                            setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.data.errorCode}.DESCRIPTION`));
                            setOpen(true);
                        }
                    }
                }
            });
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length > 0) {
                const file = target.files[0];
                uploadFile(file, URL.createObjectURL(file), 'Manual');
            }
        }
    };

    const handleRecapture = () => {
        if(selfie) {
            handleRecaptureSelfie();
        } else {
            setImgSrc(null);
            onDocumentUploaded(null);
        }
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const hiddenFileInput = React.useRef(null);

    const handleInputFileClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleInputFileChange = (event) => {
        handleCapture(event.target);
    };

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleSuccess = (captureToken, b64CaptureFile) => {
        uploadFile(dataURItoBlob(b64CaptureFile), b64CaptureFile, 'TOC');
        clearTimeout(timeoutHandler.current);
        eventTrackingService.trackEvent('TOC_completed', 'TOC', null, 0);
    };

    const handleShowTOC = () => {
        setImgSrc(null);
        onDocumentUploaded(null);
        setShowTOC(true);
        eventTrackingService.trackEvent('TOC_opened', 'TOC', null, 0);
    };

    const handleShowTOCLiveness = () => {
        handleShowTOC();
        setShowLiveness(true);
    };

    const handleRecaptureSelfie = () => {
        // TOC doesnt allow to get a new image on the same session
        getToken();
        handleShowTOC();
    };

    const CaptureDNI = () => {
        return (
            <>
                <IconButton className={classes.largeIcon} onClick={handleInputFileClick} variant="contained">
                    <Grid item container xs={12} alignItems="center">
                        <br />
                        <br />
                        <br />
                        <Grid item xs={12}>
                            <BiCloudUpload size={70} />
                        </Grid>
                        <Grid item xs={12}>
                            Clickeá para sacar la foto
                        </Grid>
                    </Grid>
                </IconButton>
                <div className="center mt-2">
                    <input
                        accept="image/*"
                        ref={hiddenFileInput}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={handleInputFileChange}
                        style={{ display: 'none' }}
                    />
                </div>
            </>
        );
    };

    const handleFailure = (errorCode) => {
        eventTrackingService.trackTocError(errorCode, t('CAPTURE_ERRORS.' + errorCode), 'liveness');
        switch (errorCode) {
            case 'EXPIRED_TOKEN':
            case '402': // time limit exceeded
                getToken();
                break;
            case '403': // cancelled by user
            case '451': // cancelled by user 
                setShowTOC(false);
                break;
            case '407': // camara no disponible
            case '409': // No hay permiso de cámara
                console.error(t('CAPTURE_ERRORS.' + errorCode));
                break;
            default:
                setShowTOC(false);
                setMessage(t('CAPTURE_ERRORS.' + errorCode));
                setOpen(true);
                break;
        }
        var node = document.getElementById('tocLivenessDivID');
        while (node && node.hasChildNodes()) {
            node.removeChild(node.firstChild);
        }
    };

    const handleLoadScript = () => {
        setLoading(false);
    }

    const loadScripts = (callback) => {
        
          const scriptAutocapture = document.createElement('script');
          scriptAutocapture.src = getEnv(TOC_AUTOCAPTURE_LIB);
          scriptAutocapture.id = 'autocapture';
          document.body.appendChild(scriptAutocapture);

          const scriptLiveness = document.createElement('script');
          scriptLiveness.src = getEnv(TOC_LIVENESS_LIB);
          scriptLiveness.id = 'liveness';
          document.body.appendChild(scriptLiveness);

          scriptLiveness.onload = () => { 
            handleLoadScript();
          };
        

      };

    const CaptureSelfie = () => {
        return !imgSrc ? (
            showTOC && showLiveness ? (
                <div className="center tocContainer">
                    <div className="toc" style={{ marginTop: '75px' }}>
                        <TocAutocaptureSelfie session={token} onSuccess={handleSuccess} onFailure={handleFailure} />
                    </div>
                </div>
            ) : (
                token ?  
                <Grid item xs={12} lg={4} className="mt-4">
                    <div className="center">
                        <img src={selfieImg} id="oldDni" alt=""></img>
                    </div>
                    <div className="center mt-2">
                        <Button onClick={handleShowTOCLiveness} className="mt-4 width-100-per" variant="contained" color="primary">
                            Realizar prueba de vida
                        </Button>
                    </div>
                </Grid>
                : null
            )
        ) : null;
    };

    return (
        <>
            <Loader loading={loading} />
            {document_ && (
                <>
                    
                    <Grid item container xs={12} alignItems="center">
                        <h2 style={{ textAlign: 'center' }}>{title} </h2>
                        <div className="mt-1"></div>
                    </Grid>
                    <div className="center mt-2">
                        <div className={!selfie ? classes.borderBox : null}>
                            <Grid item container style={{ zIndex: 100 }} xs={12} justifyContent="center" alignItems="center">
                                {!imgSrc && !selfie && <CaptureDNI />}
                                {!imgSrc && selfie && <CaptureSelfie />}
                                {imgSrc && (
                                    <>
                                        <div className="mt-4 center" style={{ justifyContent: 'center' }}>
                                            <img className={classes.captureStylePortrait} src={imgSrc} alt={document_.name} />
                                        </div>
                                        <div className="mt-4"></div>
                                        <Grid item xs={12}>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                style={{ marginTop: '20px', color: '#07a3db', fontSize: '16px', fontWeight: 'bold' }}
                                                onClick={handleRecapture}
                                                className="center width-100-per">
                                                Sacar otra vez
                                            </Link>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </div>
                    </div>
                    <Grid item container xs={12} className="mt-4 mb-2" justifyContent="center">
                        {hintInfo}
                    </Grid>
                    {open && (
                        <div className="mt-4">
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
