import React, { useEffect, useState } from 'react';


const TermsText = (props) => {
    
    return (
        <div>
            <br />
            <p>
                El titular de los datos personales <strong>presta su consentimiento libre, expreso e informado</strong> para que Banco Macro S.A.:
            </p>
            <br />
            <p>
                <strong>Trate sus datos personales, incluso los biométricos</strong>, las operaciones e información económica para prestar el servicio, calificar crediticiamente, para oferta comercial/publicitaria de producto/servicio, para control o fin estadístico, incorporando ellos a los sistemas y a las bases de datos registradas ante la Agencia de Acceso a la Información Pública o la entidad que la reemplace a futuro;
            </p>
            <br />
            <p>
                <strong>Trate, ceda o transfiera los datos, a afiliada, subsidiaria, controlante, controlada o cualquier tercero</strong>, aún en forma gratuita, manteniendo en el tratamiento la confidencialidad y la seguridad de ellos, <strong>con el fin de:</strong> (a)Analizar el riesgo para un nuevo producto o segmentar perfil comercial (b) por su cuenta o de un tercero envíen por cualquier medio, oferta comercial/publicidad personalizada o no(c)Confrontar los datos con la Base del Registro Nacional de las Personas(Renaper) para validar identidad (d) Cualquier otro similar o que reemplace o complemente a los puntos anteriormente mencionados.
            </p>
            <br />
            <p>
                <strong>Utilice tratamiento con medio técnico como por ejemplo dispositivo para captura, lectura o procesamiento de datos, incluso datos biométricos (como huella dactilar, reconocimiento facial u otro)</strong> y los compartan con terceros para resguardo de ellos o para verificación de identidad u otro fin similar;
            </p>

            <br />
            <p>
                <strong>Aloje Datos En Servidor Propio O De Tercero, En Argentina U Otro País</strong> que podría no tener legislación con nivel de protección adecuado. Y ustedes realizarán esfuerzos para lograr acuerdos con nivel de protección adecuado;
            </p>

            <br />
            <p>
                Los datos provistos serán utilizados y tratados en una base de datos registrada ante la Agencia de Acceso a la Información Pública, siendo el destinatario y titular de la misma Banco Macro S.A., con domicilio en Av. Eduardo Madero 1182 C.A.B.A. Banco Macro S.A. dará a los datos el tratamiento exigido por la Ley de Protección de Datos Personales N°25.326 y su decreto reglamentario, reservándose el derecho de ceder o transferir tales datos con fines estadísticos y/o comerciales, a sus afiliadas, subsidiarias, controlantes, controladas o cualquier tercero, aún en forma gratuita, manteniendo la confidencialidad y seguridad de los mismos, bajo las condiciones previstas por dicha ley para lo cual el titular de los datos personales lo autoriza expresamente. Como titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis (6) meses, salvo que se acredite un interés legítimo al efecto, y asimismo le asiste el derecho a solicitar en cualquier momento, la supresión, rectificación o actualización de datos erróneos en caso que ello resultare procedente (Arts. 6, 14 inc. 3 y 16 Ley 25.326). La Agencia de Acceso a la Información Pública Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.
            </p>
        </div>
    );
};


export default TermsText;
