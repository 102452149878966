import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, Container, makeStyles } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import MacroStepPage from './shared/MacroStepPage';
import Loader from '../components/loader/Loader';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import userInputService from '../services/userInput.service';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import bnplService from '../services/bnpl.service';


const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 0, 0),
        textAlign: 'center',
        fontSize: '1.55rem',
    },
}));

export const BranchOfficeBankAccountDataInput = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const [financialOfficeData, setFinancialOfficeData] = useState({});
    const [loading, setLoading] = useState(true);
    const [toast, setToast] = useState(null);
    const [form, setForm] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [commerceOperationData, setCommerceOperationData] = useState(null);
    const [financialOfficeContextData, setFinancialOfficeContextData] = useState({});

    useEffect(() => {
        async function getUserInputFields() {
            try {
                setLoading(true);
                const userInputs = userInputService.getFinancialOfficeBankAccountProvinceUserInputFields();
                setForm(userInputs);
            } catch (error) {
                // Doing nothing
            } finally {
                setLoading(false);
            }
        }
        async function getCommerceOperationData() {
            try {
                setLoading(true);
                const result = (await bnplService.getById(financialProductRequest.commerceInfo.bnplOperationId)).data;
                setCommerceOperationData(result);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
            } finally {
                setLoading(false);
            }
        }

        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
            getUserInputFields();

            setFinancialOfficeContextData((previousState) => {
                return {
                    ...previousState,
                    fundingEntityId: financialProductRequest.fundingEntityInfo.fundingEntityId.toString(),
                    customerBankAccountProvince: financialProductRequest.requestContextData.customerBankAccountProvince,
                };
            });
            getCommerceOperationData();
        }
    }, [financialProductRequest]);

    const handleNextPressed = async (event) => {
        event.preventDefault();

        const request = {
            inputMap: { financialOfficeData, financialOfficeContextData },
        };
        try {
            setLoading(true);
            await task.completeTask(request);
        } catch (error) {
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                    setToast({ message: msg });
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleUserInputChange = (userInputs, contextData) => {
        setFinancialOfficeData(userInputs);
    };

    return (
        financialProductRequest && commerceOperationData &&
        <MacroStepPage {...props} noMargin noBackground={true}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {form && (
                <Container maxWidth="sm">
                    <Grid container justifyContent="center">
                        <Grid item component={Box}>
                            <h1 className={classes.title}>{t(['branchOfficeSelectionPage.selectBranchOfficeTitle.' + commerceOperationData.providerId, 'branchOfficeSelectionPage.selectBranchOfficeTitle.default'])}</h1>
                        </Grid>
                        <Grid item container className="mt-1 center" xs={12}>
                            <p style={{ textAlign: 'center', padding: '0 20px' }}>
                                Seleccioná una sucursal en el mapa o en el listado para poder continuar.
                            </p>
                        </Grid>

                        <form onSubmit={handleNextPressed}>
                            <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                                <Grid item container className="mb-100-res">
                                    <CustomerDataUserInputs
                                        stepName={taskDefinitionId}
                                        form={form}
                                        hashKey={financialProductRequest.id}
                                        onComplete={handleUserInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="mb-footer startButton center mt-20-web">
                                        <Button
                                            className="width-100-per width-80-res"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!financialOfficeData?.customerFinancialOfficeId || loading}>
                                            Continuar
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Container>
            )}
        </MacroStepPage>
    );
};