import React, { useEffect, useState } from 'react';
import { Grid, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LandingTemplate from './shared/LandingTemplate';
import Loader from '../components/loader/Loader';
import Toaster from '../components/alerts/Toaster';
import bnplService from '../services/bnpl.service';
import financialProductsRequestsService from '../services/financialProductsRequestsService';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import { useTranslation } from 'react-i18next';
import * as qs from 'qs';
import rejectedImg from '../assets/img-rejection.svg';
import rejectedAmountImg from '../assets/img-rejection-low-offer.svg';
import errorImg from '../assets/Error.svg';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(5, 0, 0, 0),
        textAlign: 'center',
    },
    description: {
        margin: theme.spacing(5, 0, 5, 0),
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing(5, 0, 5, 0),
    },
}));

export const RejectedPage = (props) => {
    const { businessKey } = props.match.params;
    const [commerceOperationData, setCommerceOperationData] = useState(null);
    const [financialRequest, setFinancialRequest] = useState(null);
    const [offerAmount, setOfferAmount] = useState(null);
    const [loading, setLoading] = useState(null);
    const [toast, setToast] = useState(null);
    const classes = useStyles();

    const { t } = useTranslation();

    useEffect(() => {
        const getData = async (financialRequestId) => {
            try {
                setLoading(true);
                const financialRequestData = (await financialProductsRequestsService.getFinancialProductsRequest(financialRequestId)).data;
           
                console.log(financialRequestData.requestContextData.loanMaximumAmount)
                setOfferAmount(numberToCurrencyText(parseInt(financialRequestData.requestContextData.loanMaximumAmount), 2).split(',')[0])
               
                setFinancialRequest(financialRequestData);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
            } finally {
                setLoading(false);
            }
        };
        getData(businessKey);
    }, []);

    useEffect(() => {
        async function getCommerceOperationData() {
            try {
                setLoading(true);
                const result = (await bnplService.getById(financialRequest.commerceInfo.bnplOperationId)).data;
                setCommerceOperationData(result);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
            } finally {
                setLoading(false);
            }
        }

        if (financialRequest) {
            getCommerceOperationData();
        }
    }, [financialRequest]);

    const handleGoToStoreClicked = (event) => {
        event.preventDefault();
        window.location.replace(commerceOperationData.merchantReturnFailURL || commerceOperationData.merchantReturnSuccessURL);
    };

    return (
        financialRequest && commerceOperationData &&
        <LandingTemplate {...props}>
            <Toaster toast={toast} />
            <Loader loading={loading} />
            {financialRequest && (
                <Container maxWidth="sm">
                    <Grid item xs={12} container justifyContent="center">
                        <img
                            src={(financialRequest.rejectedCode === 'NO_FINANCING_OPTIONS' || financialRequest.rejectedCode === 'INSUFFICIENT_AMOUNT_OFFERED') ? rejectedAmountImg : rejectedImg}
                            className="mt-4"
                            alt=""
                        />
                    </Grid>
                    <Grid container>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={10} container>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <h1 className={classes.title}>{t([`rejectedPage.rejectedCode.${financialRequest.rejectedCode}.title.${commerceOperationData.providerId}`, 
                                        `rejectedPage.rejectedCode.${financialRequest.rejectedCode}.title.default`])}</h1>
                                    <h3 className={classes.description}>
                                        {t([`rejectedPage.rejectedCode.${financialRequest.rejectedCode}.mainDescription.${commerceOperationData.providerId}`,
                                            `rejectedPage.rejectedCode.${financialRequest.rejectedCode}.mainDescription.default`])} {offerAmount ? offerAmount : ""}{'. '}
                                        {t([`rejectedPage.rejectedCode.${financialRequest.rejectedCode}.secondaryDescription.${commerceOperationData.providerId}`,
                                            `rejectedPage.rejectedCode.${financialRequest.rejectedCode}.secondaryDescription.default`])}
                                    </h3>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    fullWidth={true}
                                    color="primary"
                                    disableElevation={true}
                                    onClick={handleGoToStoreClicked}>
                                    {t(['rejectedPage.goBackButton.' + commerceOperationData.providerId, 'rejectedPage.goBackButton.default'])}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </LandingTemplate>
    );
};
