import { useHistory } from 'react-router-dom';

const useRedirect = () => {
    const history = useHistory();

    const redirectFPRByStatus = (businessKey, status) => {
        switch (status) {
            case 'REJECTED':
                history.push(`/rejected/${businessKey}${history.location.search}`);
                break;
            case 'ABORTED':
                history.push(`/rejected/${businessKey}${history.location.search}`);
                break;
            case 'APPROVED':
                history.push(`/success/${businessKey}${history.location.search}`);
                break;
            case 'ACTIVATION_PROCESS':
                history.push(`/success/${businessKey}${history.location.search}`);
                break;
            case 'FINALIZED':
                history.push(`/success/${businessKey}${history.location.search}`);
                break;
            default:
                console.error(`Solicitud ${businessKey} se quedó sin tareas siendo que está en estado ${status}`);
                break;
        }
    };

    return {
        redirectFPRByStatus: redirectFPRByStatus,
    };

};

export default useRedirect;