import React, { useEffect, useState, Suspense } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../components/loader/Loader';
import bgMobile from '../../assets/background-landing-mobile.svg';
import logoColor from '../../assets/logoColor.svg';
import backgroundImage from '../../assets/background-bnpl.svg';
import Hidden from '@material-ui/core/Hidden';
import { ChevronLeft } from '@material-ui/icons';
import bnplService from '../../services/bnpl.service';
import SimplePopup from '../../components/popups/simplePopup';
import financialProductsRequestsService from '../../services/financialProductsRequestsService';
import redirectUtils from '../../components/bpm/useRedirect.jsx'; 
import * as qs from 'qs';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 80px)',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        justifyContent: 'space-between',
        height: '80px',
    },
    toolbarLogo: {
        height: '35px',
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: '#092f57',
    },
    image: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#092f57',
        backgroundSize: '100%',
        backgroundPosition: 'bottom right',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const LandingTemplate = (props) => {
    const { showBanner, noContainer, financialProductRequest } = props;
    const [bnplOperation, setBnplOperation] = useState(null);
    const [backToEcommercePopupVisible, setBackToEcommercePopupVisible] = useState(false);
    const classes = useStyles();
    const redirect = redirectUtils();
    const { t } = useTranslation();

    useEffect(() => {
        async function getCommerceOperationData(bnplId) {
            try {
                let result = (await bnplService.getById(bnplId)).data;
                setBnplOperation(result);
            } catch (error) {
                // Do nothing
            }
        }
        if (!bnplOperation) {
            if (props.bnplOperationId) {
                getCommerceOperationData(props.bnplOperationId);
            } else if (props && props?.location && props?.location?.search) {
                const { id: bnplId } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
                if (bnplId) {
                    getCommerceOperationData(bnplId);
                }
            }
            window.scrollTo(0, 0);
        }
    }, [props]);

    

    const getFPR = async(fprId) => {
        return (await financialProductsRequestsService.getFinancialProductsRequest(fprId)).data;
    }

    const handleBackToEcommerce = async (props) => {
        if(financialProductRequest) {
            const fpRequest = await getFPR(financialProductRequest.id);
            switch (fpRequest.status) {
                case 'ONBOARDING':
                    setBackToEcommercePopupVisible(true);
                    break;
                case 'APPROVED':
                    window.location.replace(bnplOperation.merchantReturnSuccessURL);
                    break;
                case 'ACTIVATION_PROCESS':
                    window.location.replace(bnplOperation.merchantReturnSuccessURL);
                    break;
                case 'FINALIZED':
                    window.location.replace(bnplOperation.merchantReturnSuccessURL);
                    break;
                default:
                    redirect.redirectFPRByStatus(fpRequest.id, fpRequest.status);
                    break;
            }
        } else {
            setBackToEcommercePopupVisible(true);
        }
    }

    const handleClosePopup = (props) => {
        setBackToEcommercePopupVisible(false);
    }

    const confirmBackToEcommerce = (props) => {
        window.location.replace(bnplOperation.merchantReturnFailURL);
    }

    const descriptionModal = (
        bnplOperation ?
        <>
        <div  className={`fs-14 ${classes.modalDesc}`}>
            {t(['components.landingTemplate.goBackConfirmationMessage.' + bnplOperation.providerId, 'components.landingTemplate.goBackConfirmationMessage.default'])}
        </div>
        <div style={{ fontSize: 14, fontWeight: 'bold' }}>{t(['components.landingTemplate.goBackConfirmationQuestion.' + bnplOperation.providerId, 'components.landingTemplate.goBackConfirmationQuestion.default'])}</div>
        </> 
        : <></>
    );

    return (
        <Suspense fallback={<Loading />}>
            <AppBar position="fixed" color="inherit" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img src={logoColor} className={classes.toolbarLogo} alt="" />
                    {bnplOperation && (
                        <Link onClick={handleBackToEcommerce} color="secondary" className={classes.link}>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                <ChevronLeft style={{ fontSize: 20 }} />{' '}
                                <span style={{ fontSize: 14 }}>
                                    {t([
                                        'components.landingTemplate.goBackButton.' + bnplOperation.providerId,
                                        'components.landingTemplate.goBackButton.default',
                                    ])}
                                </span>
                            </span>
                        </Link>
                    )}
                </Toolbar>
            </AppBar>
            <Toolbar style={{ height: '80px' }} />

            {showBanner && (
                <Hidden smUp>
                    <Grid item xs={12} sm={false}>
                        <img src={bgMobile} alt="" width="100%" />
                    </Grid>
                </Hidden>
            )}
            <Grid container component="main" className={classes.root}>
                <Grid item sm={4} className={classes.image} />
                {noContainer ? (
                    <Grid item xs={12} sm={8} component={Paper} elevation={0} square style={{ backgroundColor: '#fafafa' }}>
                        {props.children}
                    </Grid>
                ) : (
                    <Grid container item xs={12} sm={8} component={Paper} elevation={0} square style={{ backgroundColor: '#fafafa' }}>
                        {props.children}
                    </Grid>
                )}
            </Grid>
            {bnplOperation && (
                <SimplePopup
                    title={t([
                        'components.landingTemplate.goBackPopupTitle.' + bnplOperation.providerId,
                        'components.landingTemplate.goBackPopupTitle.default',
                    ])}
                    description={descriptionModal}
                    onClosePopup={handleClosePopup}
                    onConfirm={confirmBackToEcommerce}
                    isOpen={backToEcommercePopupVisible}
                />
            )}
        </Suspense>
    );
};

export default LandingTemplate;
