import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const PROCESS_NAME = '/macro/offer-simulations';

export const offerSimulationService = {
    simulateOffers: (offerSimulationRequest) => {
        return axiosInstance.post(`${RESTAPI_VERSION}${PROCESS_NAME}/`, offerSimulationRequest);
    }
};

export default offerSimulationService;