import React, { useState, useMemo, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import redirectUtils from '../components/bpm/useRedirect.jsx'; 
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import Toaster from '../components/alerts/Toaster';
import MacroStepPage from './shared/MacroStepPage';
import { ManualDocumentUploader } from './shared/ManualDocumentUploader';
import hintLight from '../assets/hint-light.svg';
import hintBlur from '../assets/hint-blur.svg';
import hintWarning from '../assets/hint-warning.svg';
import Loader from '../components/loader/Loader';
import eventTrackingService from '../services/eventtracking.service';
import financialProductsRequestsService from '../services/financialProductsRequestsService';
import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import { useTranslation } from 'react-i18next';

export const SelfieDocumentSelection = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId, title } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const redirect = redirectUtils();
    const [documentResponseId, setDocumentResponseId] = useState(null);
    const [activateNext, setActivateNext] = useState(false);
    const [loading, setLoading] = useState(false);
    const analyticsStep = 8;
    const { t } = useTranslation();
    const [toast, setToast] = useState(null);

    useEffect(() => {
        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
        }
    }, [financialProductRequest]);

    const onDocumentUploaded = (imgSrcUploaded, uploadMethod, documentResponse) => {
        if(documentResponse) {
            eventTrackingService.trackEvent('selfie', uploadMethod, null, analyticsStep);
            setDocumentResponseId(documentResponse.documentId);
            setActivateNext(imgSrcUploaded ? true : false);
        }
    };

    const handleNextPressed = (event) => {
        event.preventDefault();
        nextStep();
    };

    const nextStep = async () => {
        try {
            setLoading(true);
            const request = {
                inputMap: { id_selfie: documentResponseId },
            };
            await task.completeTask(request);
        } catch (error) {
            if (error.response.data.errorCode === 404) {
                const fpRequest = (await financialProductsRequestsService.getFinancialProductsRequest(businessKey)).data;
                redirect.redirectFPRByStatus(businessKey, fpRequest.status);
            } else {
                setToast({ message: "No pudimos avanzar con tu solicitud. Volvé a intentar en unos segundos." });
            }
        } finally {
            setLoading(false);
        }
    };

    const hintDni = useMemo(() => {
        return (
            <div className="center mb-16">
                <div>
                    <div align="center">
                        <img src={hintLight} alt="" style={{ marginRight: '12px' }} />
                        Buscá un lugar bien iluminado
                    </div>

                    <div align="center">
                        <img src={hintBlur} alt="" style={{ marginRight: '16px' }} />
                        Despejá tu rostro
                    </div>

                    <div align="center">
                        <img src={hintWarning} alt="" style={{ marginRight: '16px' }} />
                        Ubicá tu rostro dentro de la figura
                    </div>
                </div>
            </div>
        );
    }, []);

    return (
        <MacroStepPage {...props} noBackground={true} financialProductRequest={financialProductRequest}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {isMobile ?
                <form onSubmit={handleNextPressed}>
                    <Grid item container className="mb-4" direction="row" justifyContent="center" alignItems="center" xs={12}>
                        {task && task.task && (
                            <Grid item container style={{ zIndex: 1 }} xs={12} direction="row" justifyContent="center">
                                <ManualDocumentUploader
                                    title={title}
                                    taskId={task.task.taskId}
                                    hintInfo={hintDni}
                                    processInstanceId={processInstanceId}
                                    businessKey={businessKey}
                                    document_={'selfie'}
                                    onDocumentUploaded={onDocumentUploaded}
                                    onHandleNext={handleNextPressed}
                                    selfie={true}
                                />
                            </Grid>
                        )}
                        <br />
                    </Grid>
                    {activateNext && <Grid item xs={12} sm={5} md={6} lg={4}>
                        <div className="startButton center mb-20-web mt-20-web">
                            <Button className="width-100-per width-80-res" type="submit" variant="contained" color="primary">
                                Continuar
                            </Button>
                        </div>
                    </Grid>}
                </form>
            :
            <ContinueMobile processInstanceId={processInstanceId} businessKey={businessKey} />
            }
        </MacroStepPage>
    );
};
