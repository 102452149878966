import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CustomTypeahead } from '../customInputs/CustomTypeahead';
import { UNDEFINED_VALUE } from './userInputConstants';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';
import Toaster from '../../components/alerts/Toaster';
import userInputService from '../../services/userInput.service';

const OPTIONS_LIMIT = 30;

const TypeaheadUserInput = (props) => {
    const { userInput, onChange, parentElementConditionalValue, elementConditionalId, elementConditionalValue } = props;
    const [comboList, setComboList] = useState(undefined);
    const [value, setValue] = useState(null);
    const [description, setDescription] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toasterOpen, setToasterOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    const [defaultValue, setDefaultValue] = useState(props.userInput.defaultValue);

    useEffect(() => {
        async function getComboData() {
            try {
                setLoading(true);
                let result = [];
                var totalPages = 1;
                var pageNumber = 0;
                while (pageNumber < totalPages) {
                    pageNumber++;
                    const partialResult = (
                        await userInputService.getComboList(userInput.name, parentElementConditionalValue, elementConditionalValue, pageNumber)
                    ).data;
                    result.push(...partialResult.result);
                    totalPages = partialResult.totalPages;

                    if (partialResult.result.length < 1000) {
                        return result;
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (elementConditionalId) {
            userInput.elementConditionalDefinitions.forEach((conditionalDefinition) => {
                if (conditionalDefinition.formElementId === elementConditionalId) {
                    if (elementConditionalValue && elementConditionalValue !== null) {
                        const comboData = getComboData().then((response) => {
                            setComboList(response);
                        });
                    } else if (elementConditionalValue === null) {
                        setComboList(null);
                    }
                }
            });
        } else {
            setComboList(null);
        }
    }, [elementConditionalId, elementConditionalValue]);

    useEffect(() => {
        if (value && value !== UNDEFINED_VALUE) {
            onChange(userInput, value.id, value.description);
        } else {
            onChange(userInput, null);
        }
    }, [value]);

    useEffect(() => {
        if (comboList) {
            if (comboList.length === 1) {
                setValue(comboList[0]);
                onChange(userInput, comboList[0], comboList[0]);
            } else {
                if(defaultValue) {
                    comboList.forEach((item) => {
                        if (item.selected || item.id == defaultValue) {
                            setValue(item);
                            setDefaultValue(null);
                        }
                    });
                } else {
                    setValue(null);
                    onChange(userInput, null);
                }
            }
        } else {
            setValue(null);
            onChange(userInput, null);
        }
    }, [comboList]);

    useEffect(() => {
        async function getComboData() {
            try {
                let result = [];
                var pageNumber = 0;
                var totalPages = 1;
                while (pageNumber < totalPages) {
                    pageNumber++;
                    const partialResult = (await userInputService.getComboList(userInput.name, null, null, pageNumber)).data;
                    result.push(...partialResult.result);
                    totalPages = partialResult.totalPages;
                    if (partialResult.result.length < 1000) {
                        setComboList(result);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        if (userInput && userInput.elementConditionalDefinitions.length === 0) {
            getComboData();
        }
    }, [userInput]);

    const handleComboChange = (event, value, reason) => {
        if (value && value.id) {
            setValue(value);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setToasterOpen(false);
    };

    const showErrorOnToaster = (errorCode) => {
        const UNKNOWN_ERROR_KEY = 'WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION';
        let translationKey = `WORKFLOW_ERROR.${errorCode}.DESCRIPTION`;
        let translation = t(translationKey);
        if (translation !== translationKey) {
            setErrorMessage(translation);
        } else {
            setErrorMessage(t(UNKNOWN_ERROR_KEY));
        }
        setToasterOpen(true);
    };

    return (
        <>
            <Loader loading={loading} />
            <ShowToaster open={toasterOpen} textToShow={errorMessage} type="error" handleToasterClose={handleToasterClose} />
            {comboList && comboList.length > 0 && (
                <CustomTypeahead
                    fullWidth={true}
                    required={userInput.required}
                    autoSelect={true}
                    disableClearable={true}
                    disabled={comboList.length < 1}
                    disableListWrap
                    name={userInput.name}
                    options={comboList}
                    filterOptions={createFilterOptions({
                        limit: OPTIONS_LIMIT,
                        ignoreAccents: false,
                    })}
                    getOptionLabel={(comboItem) => {
                        if (!comboItem) {
                            return '';
                        }
                        return comboItem.description ? comboItem.description : comboItem.code;
                    }}
                    getOptionSelected={(option, value) => value === option.id}
                    onChange={handleComboChange}
                    value={value}
                    errorHelperText="Requerido"
                    helperText={userInput.helperText}
                    //helperText={userInput.userInputOptions.hint}
                >
                    <TextField
                        variant="outlined"
                        label={userInput.label}
                        required={userInput.required}
                        //disabled={userInput.userInputOptions.disabled}
                    />
                </CustomTypeahead>
            )}
        </>
    );
};

export default TypeaheadUserInput;
