//import React from 'react';
import React, { useState, useEffect } from 'react';
import TextUserInput from './TextUserInput';
import ComboUserInput from './ComboUserInput';
//import DateUserInput from './DateUserInput';
import CheckUserInput from './CheckUserInput';
import TypeaheadUserInput from './TypeaheadUserInput';
import EmailUserInput from './EmailUserInput';
import MapUserInput from './MapUserInput';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    pr5: {
        paddingRight: '8px',
        marginTop: '10px',
    },
}));

const UserInput = (props) => {
    //
    const { userInput, hashKey, stepName, onChange, parentElementConditionalValue, elementConditionalId, elementConditionalValue } = props;
    const options = userInput.formElementType;

    const handleValueChange = (userInput, value, description) => {
        onChange(userInput, value, description);
    };

    useEffect(() => {}, [userInput]);

    const classes = useStyles();

    return (
        <>
            {userInput && options && (
                <>
                    {options === 'MANUAL_TEXT' && (
                        <Grid container className={classes.pr5}>
                            <TextUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}

                    {options.type === 'EMAIL' && (
                        <Grid container className={classes.pr5}>
                            <EmailUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}

                    {options.type === 'TEXT_AUTOCOMPLETE' && (
                        <Grid container className={classes.pr5}>
                            <TextUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}

                    {options === 'MANUAL_COMBO' && (
                        <Grid container className={classes.pr5}>
                            <ComboUserInput
                                userInput={userInput}
                                onChange={handleValueChange}
                                conditioningValue={props.conditioningValue}
                                parentConditioningValue={props.parentConditioningValue}
                                parentElementConditionalValue={parentElementConditionalValue}
                                elementConditionalId={elementConditionalId}
                                elementConditionalValue={elementConditionalValue}
                            />
                        </Grid>
                    )}
                    {options === 'TYPEAHEAD' && (
                        <Grid container className={classes.pr5}>
                            <TypeaheadUserInput
                                hashKey={hashKey}
                                stepName={stepName}
                                userInput={userInput}
                                onChange={handleValueChange}
                                conditioningValue={props.conditioningValue}
                                parentConditioningValue={props.parentConditioningValue}
                                parentElementConditionalValue={parentElementConditionalValue}
                                elementConditionalId={elementConditionalId}
                                elementConditionalValue={elementConditionalValue}
                            />
                        </Grid>
                    )}

                    {options === 'CHECK' && (
                        <Grid container className="mt-1">
                            <CheckUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}
                    {options === 'MAP' && (
                        <Grid container>
                            <MapUserInput
                                hashKey={hashKey}
                                userInput={userInput}
                                onChange={handleValueChange}
                                stepName={stepName}
                                conditioningValue={props.conditioningValue}
                                parentConditioningValue={props.parentConditioningValue}
                            />
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default UserInput;
