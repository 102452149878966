import React, { useState, useEffect } from 'react';
import { UNDEFINED_VALUE } from './userInputConstants';
import { CustomTextField } from '../customInputs/CustomTextField';

const TextUserInput = (props) => {
    const { userInput, onChange } = props;
    const [value, setValue] = useState(userInput.defaultValue || UNDEFINED_VALUE);
    const [regex, setRegex] = useState(null); 
    
    useEffect(() => {
        if (value) {
            onChange(userInput, value);
        }
    }, [value]);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (userInput) {
            if(userInput.pattern) {
                setRegex(new RegExp(userInput.pattern));
            }
        }
    }, [userInput]);

    const handleTextFieldChange = (event) => {
        const newValue = event.target.value;

        if(!regex || newValue.match(regex)) {
            setValue(newValue);
        } 
    };

    return (
        <CustomTextField 
            disabled={false} //userInput.userInputOptions.disabled
            fullWidth={true}
            required={userInput.required} //userInput.userInputOptions.required
            value={value}
            name={userInput.name}
            label={userInput.label} //userInput.userInputOptions.label
            onChange={handleTextFieldChange}
            errorHelperText="Requerido"
            //helperText={userInput.userInputOptions.hint}
        />
    );
};

export default TextUserInput;
