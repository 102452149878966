import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const FINANCIAL_PRODUCT_REQUESTS_PATH = 'financial-products-requests';

export const financialProductsRequestsService = {
    getFinancialProductsRequest: (requestId) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/${FINANCIAL_PRODUCT_REQUESTS_PATH}/${requestId}`);
    },
    getDocument: (requestId, documentId) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/${FINANCIAL_PRODUCT_REQUESTS_PATH}/${requestId}/documents/${documentId}`);
    },
    getDocumentFile: (requestId, documentId, fileId) => {
        return axiosInstance({
            method: 'get',
            url: `${RESTAPI_VERSION}/${FINANCIAL_PRODUCT_REQUESTS_PATH}/${requestId}/documents/${documentId}/files/${fileId}`,
            responseType: 'blob',
        });
    }
};

export default financialProductsRequestsService;