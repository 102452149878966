import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, Container, makeStyles, Paper, Typography, Link } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import Loader from '../components/loader/Loader';
import useFinancialProductsRequest from '../hooks/useFinancialProductsRequest';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import userInputService from '../services/userInput.service';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import bnplService from '../services/bnpl.service';
import LandingTemplate from './shared/LandingTemplate';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(10, 2, 2, 2),
        textAlign: 'center',
        fontSize: '1.55rem',
    },
    branchOfficesLink: {
        margin: theme.spacing(1, 0, 0, 0),
    },
    topBar: {
        backgroundColor: '#fafafa',
    },
    topBarSpacing: {
        margin: theme.spacing(2),
    },
    subtitle: {
        margin: theme.spacing(0, 5, 1, 5),
        textAlign: 'center',
    },
    continueButton: {
        margin: theme.spacing(5, 0, 5, 0),
    },
}));

export const BankAccountProvinceDataInput = (props) => {
    const { businessKey } = props.match.params;
    const financialProductRequest = useFinancialProductsRequest(businessKey);
    const { taskDefinitionId } = props;
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const task = useCurrentTask(processInstanceId, businessKey, taskDefinitionId);
    const [provinceAccountData, setProvinceAccountData] = useState({});
    const [loading, setLoading] = useState(true);
    const [toast, setToast] = useState(null);
    const [form, setForm] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [commerceOperationData, setCommerceOperationData] = useState(null);

    useEffect(() => {
        async function getUserInputFields() {
            try {
                setLoading(true);
                const userInputs = userInputService.getBankAccountProvinceUserInputFields();
                setForm(userInputs);
            } catch (error) {
                // Doing nothing
            } finally {
                setLoading(false);
            }
        }

        async function getCommerceOperationData() {
            try {
                setLoading(true);
                const result = (await bnplService.getById(financialProductRequest.commerceInfo.bnplOperationId)).data;
                setCommerceOperationData(result);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
            } finally {
                setLoading(false);
            }
        }

        if (financialProductRequest) {
            setProcessInstanceId(financialProductRequest.businessProcessInfo.processInstanceId);
            getUserInputFields();
            getCommerceOperationData();
        }
    }, [financialProductRequest]);

    useEffect(() => {
        async function complete() {
            const request = {
                inputMap: { provinceAccountData },
            };
            try {
                setLoading(true);
                await task.completeTask(request);
            } catch (error) {
                if (error.response) {
                    const { cause, code } = error.response.data;
                    if (error.response.data.code === 409) {
                        setToast({ message: t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`) });
                    } else {
                        setToast({ message: t(`WORKFLOW_ERROR.GENERAL.DESCRIPTION`) });
                    }
                }
            } finally {
                setLoading(false);
            }
        }

        if (provinceAccountData.customerAddressCountry) {
            complete();
        }
    }, [provinceAccountData]);

    const handleNextPressed = async (event) => {
        event.preventDefault();

        setProvinceAccountData((previousState) => {
            return { ...previousState, customerAddressCountry: '1' };
        });
    };

    const handleUserInputChange = (userInputs, contextData) => {
        setProvinceAccountData(userInputs);
    };

    const handleRedirectBranchOffices = () => {
        window.open('https://www.macro.com.ar/conocenos/canales-de-atencion/buscador-de-cajeros-y-sucursales', '_blank');
    };

    return (
        <LandingTemplate noContainer bnplOperationId={financialProductRequest} {...props}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {commerceOperationData && (
                <Grid className={classes.topBar} component={Paper} elevation={0} alignItems="center">
                    <Grid item className={classes.topBarSpacing}>
                        <h4>Hola {financialProductRequest.customerFullName}</h4>
                        <p>
                            {t(['bankAccountProvinceSelectionPage.title.' + commerceOperationData.providerId, 'bankAccountProvinceSelectionPage.title.default'])}<strong>{commerceOperationData.merchantOrderID}</strong> por{' '}
                            <strong>{numberToCurrencyText(commerceOperationData.cartAmount, 2)}</strong>.
                        </p>
                    </Grid>
                </Grid>
            )}
            {form && commerceOperationData && (
                <Container maxWidth="sm">
                    <Grid sm={12}>
                        <Grid item>
                            <h1 className={classes.title}>{t(['bankAccountProvinceSelectionPage.selectProvinceTitle.' + commerceOperationData.providerId, 'bankAccountProvinceSelectionPage.selectProvinceTitle.default'])}</h1>
                        </Grid>
                        <Grid item container className="mt-1 center" xs={12}>
                            <h3 style={{ textAlign: 'center' }}>
                                Necesitamos saber la provincia para el cálculo de la oferta. Más adelante podrás elegir la sucursal dentro de la
                                provincia indicada.
                            </h3>
                        </Grid>

                        <form onSubmit={handleNextPressed}>
                            <Grid item direction="row" justifyContent="center" alignItems="center" xs={12}>
                                <CustomerDataUserInputs
                                    //sameLineIndexes={[1, 2, 3]}
                                    stepName={taskDefinitionId}
                                    form={form}
                                    hashKey={financialProductRequest.id}
                                    onComplete={handleUserInputChange}
                                />
                                <div className="mb-footer startButton center mt-20-web">
                                    <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                                        <Button
                                            className="width-100-per width-80-res"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}>
                                            Continuar
                                        </Button>

                                        <Typography variant="body1" className={classes.branchOfficesLink} align="center">
                                            Podés ver{' '}
                                            <Link href="#" color="secondary" onClick={handleRedirectBranchOffices}>
                                                acá
                                            </Link>{' '}
                                            las sucursales de Banco Macro.
                                        </Typography>
                                    </Grid>
                                </div>
                            </Grid>
                        </form>
                    </Grid>
                </Container>
            )}
        </LandingTemplate>
    );
};
