import React, { useEffect, useState, Suspense } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../components/loader/Loader';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import logo from '../../assets/logo.svg';
import backgroundImage from '../../assets/background-validation.svg';
import bnplService from '../../services/bnpl.service';
import qs from 'qs';
import SimplePopup from '../../components/popups/simplePopup';
import redirectUtils from '../../components/bpm/useRedirect.jsx'; 
import financialProductsRequestsService from '../../services/financialProductsRequestsService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 80px)',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#092f57',
    },
    toolbar: {
        justifyContent: 'space-between',
        height: '80px',
    },
    toolbarLogo: {
        height: '35px',
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    image: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#092f57',
        backgroundSize: '50%',
        backgroundPosition: 'top right',
    },
    paper: {
        margin: theme.spacing(5, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    noPaper: {
        marginTop: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const MacroStepPage = (props) => {
    const { noBackground, noMargin, financialProductRequest } = props;
    const classes = useStyles();
    const [bnplOperation, setBnplOperation] = useState(null);
    const [backToEcommercePopupVisible, setBackToEcommercePopupVisible] = useState(false);
    const redirect = redirectUtils();
    const { t } = useTranslation();

    useEffect(() => {
        async function getCommerceOperationData(bnplId) {
            try {
                let result = (await bnplService.getById(bnplId)).data;
                setBnplOperation(result);
            } catch (error) {
                // Do nothing
            }
        }

        if (props.location.search) {
            const { id: bnplId } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            if (bnplId) {
                getCommerceOperationData(bnplId);
            }
        }
        window.scrollTo(0, 0);
    }, []);

    const confirmBackToEcommerce = (props) => {
        window.location.replace(bnplOperation.merchantReturnFailURL);
    }
    
    const getFPR = async(fprId) => {
        return (await financialProductsRequestsService.getFinancialProductsRequest(fprId)).data;
    }

    const handleBackToEcommerce = async (props) => {
        if(financialProductRequest) {
            const fpRequest = await getFPR(financialProductRequest.id);
            switch (fpRequest.status) {
                case 'ONBOARDING':
                    setBackToEcommercePopupVisible(true);
                    break;
                case 'APPROVED':
                    window.location.replace(bnplOperation.merchantReturnSuccessURL);
                    break;
                case 'ACTIVATION_PROCESS':
                    window.location.replace(bnplOperation.merchantReturnSuccessURL);
                    break;
                case 'FINALIZED':
                    window.location.replace(bnplOperation.merchantReturnSuccessURL);
                    break;
                default:
                    redirect.redirectFPRByStatus(fpRequest.id, fpRequest.status);
                    break;
            }
        } else {
            setBackToEcommercePopupVisible(true);
        }
    }

    

    const handleClosePopup = (props) => {
        setBackToEcommercePopupVisible(false);
    }

    const descriptionModal = (
        bnplOperation ?
        <>
        <div  className={`fs-14 ${classes.modalDesc}`}>
            En caso de querer financiar el producto con Macro deberás cargar tus datos nuevamente.
        </div>
        <div style={{ fontSize: 14, fontWeight: 'bold' }}>{t(['macroStepPage.goBackConfirmationMessage.' + bnplOperation.providerId, 'macroStepPage.goBackConfirmationMessage.default'])}</div>
        </>
        : <></>
    );
    
    return (
        <>
            <Suspense fallback={<Loading />}>
                <AppBar position="fixed" color="secondary" elevation={0} className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <img src={logo} className={classes.toolbarLogo} alt="" />
                        {bnplOperation && (
                            <Link onClick={handleBackToEcommerce} color="inherit" className={classes.link}>
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                    <ChevronLeft style={{ fontSize: 20 }} /> <span style={{ fontSize: 14 }}>{t(['macroStepPage.goBackButton.' + bnplOperation.providerId, 'macroStepPage.goBackButton.default'])}</span>
                                </span>
                            </Link>
                        )}
                    </Toolbar>
                </AppBar>
                <Toolbar style={{ height: '80px' }} />
                <Grid container component="main" className={classes.root}>
                    {noMargin ? (
                        <Grid item container xs={12} component={Paper} className={classes.noPaper} elevation={0} square>
                            {props.children}
                        </Grid>
                    ) : (
                        <Grid item container xs={12} component={Paper} elevation={0} square className={noBackground ? classes.paper : classes.image}>
                            {props.children}
                        </Grid>
                    )}
                </Grid>
                {bnplOperation && <SimplePopup title={t(['macroStepPage.goBackPopupTitle.' + bnplOperation.providerId, 'macroStepPage.goBackPopupTitle.default'])} description={descriptionModal} onClosePopup={handleClosePopup} onConfirm={confirmBackToEcommerce} isOpen={backToEcommercePopupVisible}/>}
            </Suspense>
        </>
    );
};

export default MacroStepPage;
