import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Modal } from '@material-ui/core';
import '../../styles/components/documentsUpload.scss';
import Loader from '../loader/Loader';
import Qrcode from './qrcode';
import useFinancialProductsRequests from '../../hooks/useFinancialProductsRequest';
import bnplService from '../../services/bnpl.service';
import { numberToCurrencyText } from '../../components/utils/numberUtils';
import { bpmService } from '../../services/bpm.service';
import LandingTemplate from '../../pages/shared/LandingTemplate';
import Smartphone from '../../assets/Smartphone.svg';
import { useStyles } from './styles';
import financialProductsRequestsService from '../../services/financialProductsRequestsService';
import Toaster from '../alerts/Toaster';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MAX_WAIT_TIME = 60;

export const ContinueMobile = (props) => {
    const { businessKey, processInstanceId } = props;
    const [validationCodeSentSms, setValidationCodeSentSms] = useState(false);
    const [smsRetryIntervalId, setSmsRetryIntervalId] = useState(null);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);
    const [loading, setLoading] = useState(null);
    const financialProductRequest = useFinancialProductsRequests(businessKey);
    const [commerceOperationData, setCommerceOperationData] = useState(null);
    const classes = useStyles();
    const [bnplOperationId, setBnplOperationId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [toast, setToast] = useState(null);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        return () => clearInterval(smsRetryIntervalId);
    }, []);

    useEffect(() => {
        async function getCommerceOperationData() {
            try {
                setLoading(true);
                const result = (await bnplService.getById(financialProductRequest.commerceInfo.bnplOperationId)).data;
                setCommerceOperationData(result);
            } catch (error) {
                setToast({ message: 'Algo salió mal al buscar tu orden. Reintentá en unos segundos' });
            } finally {
                setLoading(false);
            }
        }

        if (financialProductRequest) {
            setBnplOperationId(financialProductRequest.requestContextData.ecommerceIntegrationRequestId);
            getCommerceOperationData();
        }
    }, [financialProductRequest]);

    useEffect(() => {
        if (seconds <= 0) {
            setValidationCodeSentSms(false);
            setSeconds(MAX_WAIT_TIME);
        }
    }, [seconds]);

    const handleSendSMSLink = () => {
        setShowModal(true);

        setValidationCodeSentSms(true);

        bpmService
            .searchWorkflowBusinessInstancePendingTasks(processInstanceId)
            .then(function (response) {
                const taskId = response.data.result[0].taskId;

                // Manejar el envío de sms con un trigger en el backend
                const payload = {
                    eventName: 'sendRedirectionSms',
                };

                bpmService.triggerTaskEvent(processInstanceId, taskId, payload).catch(function (error) {
                    console.error(error);
                    setToast({ message: 'Algo salió mal al enviar el SMS. Reintentá en unos segundos' });

                    setValidationCodeSentSms(false);
                });

                clearInterval(smsRetryIntervalId);
                const intervalId = setInterval(() => {
                    setSeconds((previousSeconds) => {
                        return previousSeconds - 1;
                    });
                }, 1000);
                setSmsRetryIntervalId(intervalId);
            })
            .catch(function (error) {
                console.error(error);
                setToast({ message: 'Algo salió mal al enviar el SMS. Reintentá en unos segundos' });

                setValidationCodeSentSms(false);
            });
    };

    const handleContinueDesktop = async () => {
        const fpRequest = (await financialProductsRequestsService.getFinancialProductsRequest(businessKey)).data;
        if(fpRequest.identityVerificationInfo && fpRequest.identityVerificationInfo.identityVerified) {
            
            try {
                const pendingTasksResponse = (await bpmService.searchWorkflowBusinessInstancePendingTasks(processInstanceId));
                const currentTask = pendingTasksResponse.data.result[0];
                if (currentTask) {
                    history.push(`/${currentTask.taskDefinitionId}/${businessKey}${history.location.search}`);
                } else {
                    redirect.redirectFPRByStatus(businessKey, fpRequest.status);
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.data.code === 404) {
                        redirect.redirectFPRByStatus(businessKey, fpRequest.status);
                    }
                }
                setToast({ message: "Ha ocurrido un error. Volvé a intentar." });
            }
        } else {
            setToast({ message: 'Es necesario que finalices la validación de identidad para poder continuar.' });
        }
    }

    const ModalBody = (
        <div className={classes.modalBody}>
            <h3 className={classes.modalTitle}>
                <b>Enviamos un SMS a tu celular</b>
            </h3>
            <div className={`fs-14 ${classes.modalDesc}`}>Por favor seguí los pasos que se indican en el link</div>
            <Button
                className={classes.modalBtn}
                onClick={() => setShowModal(false)}
                disabled={validationCodeSentSms}
                variant="contained"
                color="primary">
                Aceptar
            </Button>
            {validationCodeSentSms && (
                <div className={classes.timeoutContainer}>
                    <i className="mt-1 fs-14 fst-reg color-secondary fw-bold">¿No recibiste tu código?</i>
                    <Box>
                        <i className="fs-14 fw-400 fst-reg"> Podrás reenviarlo en {seconds} segundos</i>
                    </Box>
                </div>
            )}
        </div>
    );

    return (
        <LandingTemplate bnplOperationId={bnplOperationId} financialProductRequest={financialProductRequest} {...props}>
            <Grid container component="main" className={classes.root}>
                <Toaster toast={toast} />
                <Grid item container xs={12} sm={12} elevation={0} square style={{ backgroundColor: '#fafafa' }}>
                    {financialProductRequest && commerceOperationData && (
                        <Grid item xs={12} className={classes.subHeaderTitle}>
                            <h4>Hola {financialProductRequest.customerFullName}</h4>
                            <p>
                                {t(['components.continueMobile.title.' + commerceOperationData.providerId, 'components.continueMobile.title.default'])}<strong>{commerceOperationData.merchantOrderID}</strong> por{' '}
                                <strong>{numberToCurrencyText(commerceOperationData.cartAmount, 2)}</strong>.
                            </p>
                        </Grid>
                    )}
                    <Grid
                        item
                        direction="column"
                        justifyContent="center"
                        xs={12}
                        alignItems="center"
                        style={{ display: 'flex', textAlign: 'center', marginTop: 20 }}>
                        <h1>Para continuar necesitamos validar tu identidad.</h1>
                    </Grid>
                    
                    <div className={classes.subtitle}>
                        <p className={`fs-14 fw-400 ${classes.subtitleChild}`}>
                            Podemos <b>enviarte un SMS</b> con un link o <b>escanear el QR</b> para iniciar el proceso. Vas a necesitar tu DNI y
                            sacarte una selfie.
                        </p>
                    </div>
                    <Grid item xs={12} sm={2} style={{ display: 'flex' }} direction="column" alignItems="center"></Grid>
                    <Grid item xs={12} sm={4} style={{ display: 'flex' }} direction="column" alignItems="center">
                        <div className="center" style={{ paddingTop: 0 }}>
                            <Qrcode className="center" businessKey={businessKey} />
                        </div>
                        <p className="fs-16" style={{ lineHeight: '20px', color: '#092f57' }}>
                            <b>Escanear el QR</b>
                        </p>
                        <div className="fs-14" style={{ lineHeight: '20px', marginBottom: 20, textAlign: 'center', width: '150px' }}>
                            Te redigiremos automáticamente
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ display: 'flex' }} direction="column" alignItems="center">
                        <div className="center" style={{ paddingTop: 0 }}>
                            <img src={Smartphone} width="200px" height="150px" />
                        </div>
                        <p className="fs-16" style={{ lineHeight: '20px', marginTop: 20, color: '#092f57' }}>
                            <b>Enviar SMS</b>
                        </p>
                        <div className="fs-14" style={{ lineHeight: '20px', textAlign: 'center', width: '150px' }}>
                            Vas a recibir un link para continuar
                        </div>
                        <Loader loading={loading} />
                        <div style={{ marginTop: 10 }}>
                            <Button
                                style={{ width: '180px', height: '40px' }}
                                onClick={handleSendSMSLink}
                                disabled={validationCodeSentSms}
                                variant="contained"
                                color="primary">
                                Enviar
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ display: 'flex' }} direction="column" alignItems="center"></Grid>
                </Grid>
                <Grid
                    item
                    direction="column"
                    justifyContent="center"
                    xs={12}
                    alignItems="center"
                    style={{ textAlign: 'center', marginTop: 30 }}>
                        
                    <span className="fs-14">
                        Una vez que finalices la carga desde el celular podés continuar tu solicitud {' '}
                        <a href="#" onClick={handleContinueDesktop}>
                            desde aquí
                        </a>
                    </span>
                </Grid>
            </Grid>
            
            <Modal
                open={showModal}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {ModalBody}
            </Modal>
        </LandingTemplate>
    );
};
export default ContinueMobile;
